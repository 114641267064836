import React, { useContext, useState } from 'react'
import {
  Typography,
  Grid,
  Button,
  TextField,
  Stack,
  CircularProgress
} from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Error as ErrorIcon
} from '@mui/icons-material'
import {
  Color,
  EditIcon,
  InfoBanner,
  Menu,
  AddIcon,
  InfoIcon,
  DialogWithoutAction
} from 'components'
import { AlertContext } from 'context/AlertContext'
import { StaffAccountContext } from 'context/StaffAccountProvider'
import { MicroServiceContext } from 'context/MicroService'
import useStyles from './style'

enum ErrorEmailStatus {
  failed = 'failed',
  reactivationRequired = 'reactivationRequired'
}

type ErrorEmail = {
  email: string
  status: ErrorEmailStatus
}
interface Props {
  isInEditMode: boolean
  setIsInEditMode: (bool: boolean) => void
}

const TableTitle = (props: Props) => {
  const { isInEditMode, setIsInEditMode } = props
  const [manageAnchorEl, setManageAnchorEl] = useState<
    undefined | HTMLElement
  >()
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const { displayAlertMessage } = useContext(AlertContext)
  const { inviteStaff, activateStaff } = useContext(StaffAccountContext)
  const { isFlagEnabled } = useContext(MicroServiceContext)
  const showInviteOption: boolean = isFlagEnabled('enable-invite-staff')
  const [errorEmails, setErrorEmails] = useState<ErrorEmail[] | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [emails, setEmails] = useState('')
  const classes = useStyles()

  const handleSubmitInvite = async () => {
    const removeSpace = (email: string) => {
      return email.replace(/\s+/g, '')
    }
    const emailList = emails.split('\n').map(email => removeSpace(email))

    const { data } = await inviteStaff({
      variables: { emails: emailList.filter(email => email) }
    })

    const {
      inviteEmployerStaffAccounts: { failed, reactivationRequired }
    } = data
    setEmails('')
    setLoading(false)

    if (failed.length > 0 || reactivationRequired.length > 0) {
      setErrorEmails([
        ...failed.map((email: string) => ({
          email,
          status: ErrorEmailStatus.failed
        }))
      ])

      reactivationRequired.forEach(async (email: string) => {
        activateStaff(email)
      })
    } else {
      setInviteDialogOpen(false)
      displayAlertMessage('Invite sent')
    }
  }

  const isThereErrorEmails = errorEmails && errorEmails.length > 0
  return (
    <>
      <DialogWithoutAction
        open={inviteDialogOpen}
        onClose={() => {
          setInviteDialogOpen(false)
          setErrorEmails([])
        }}
        title={isThereErrorEmails ? 'Invalid email address' : 'Invite staff'}
        content={
          isThereErrorEmails
            ? 'Add the email addresses of the people you want to invite to your organization. It may take a few hours for invitations to arrive.'
            : undefined
        }
        Action={
          <Stack direction='row' spacing={1}>
            <Button
              color='secondary'
              variant='contained'
              className={classes.button}
              onClick={() => {
                setInviteDialogOpen(false)
                setErrorEmails([])
              }}
            >
              Cancel
            </Button>
            <Button
              startIcon={loading && <CircularProgress size={20} />}
              variant='contained'
              color='primary'
              disabled={loading || !emails}
              className={classes.button}
              onClick={() => handleSubmitInvite()}
            >
              Send
            </Button>
          </Stack>
        }
      >
        {isThereErrorEmails ? (
          <Grid
            item
            className={classes.errorBanner}
            container
            flexDirection='column'
            wrap='nowrap'
          >
            <Typography variant='body1' color={Color.black}>
              Check the following email address(es) for errors and reenter the
              correct version(s) below. If that still doesn’t work, contact your
              Impact Manager for help.
            </Typography>
            <Grid
              container
              flexDirection='column'
              maxHeight='120px'
              flexWrap='nowrap'
              marginTop='3px'
              className={classes.emailList}
            >
              {errorEmails.map((email: ErrorEmail) => (
                <Grid
                  key={email.email}
                  item
                  marginTop='9px'
                  container
                  alignItems='center'
                >
                  <ErrorIcon sx={{ color: '#DC1849' }} fontSize='small' />
                  <Typography
                    component='span'
                    variant='body1'
                    color={Color.black}
                    marginLeft='13px'
                  >
                    {email.email}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            className={classes.banner}
            container
            flexDirection='row'
            wrap='nowrap'
            marginBottom='16px'
          >
            <InfoIcon filled className={classes.infoIcon} />
            <Typography variant='body1' color={Color.black80}>
              New staff are invited as Basic Users and don’t count against your
              seats. You can edit roles once they’ve created an account.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} paddingBottom='8px'>
          <Typography variant='formLabel' marginBottom='5px'>
            Email addresses
          </Typography>
          <Typography variant='body2' color={Color.black60}>
            Separate email addresses onto new lines
          </Typography>
        </Grid>
        <Grid item xs={12} paddingBottom='16px'>
          <TextField
            multiline
            fullWidth
            value={emails}
            inputProps={{ className: classes.input }}
            rows={3}
            maxRows={50}
            onChange={e => setEmails(e.target.value)}
            placeholder={
              errorEmails ? undefined : 'name@company.com\nname1@company.com'
            }
          />
          {!errorEmails && (
            <Typography
              component='p'
              variant='caption'
              color={Color.black60}
              textAlign='right'
            >
              50 lines max
            </Typography>
          )}
        </Grid>
      </DialogWithoutAction>
      <Grid position='relative' width='100%'>
        {isInEditMode && (
          <InfoBanner
            type='info'
            sx={{
              borderRadius: '10px',
              padding: '24px 14px',
              width: 'calc(100% + 24px)',
              marginBottom: '52px'
            }}
            content={
              <>
                <Typography variant='body2' color={Color.grey700}>
                  You&apos;re in editing mode
                </Typography>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    setIsInEditMode(false)
                  }}
                  sx={{ position: 'absolute', right: -8, top: 16 }}
                >
                  Stop editing
                </Button>
              </>
            }
          />
        )}
        <Grid container item>
          <Grid item xs={6} container spacing={3} />
          <Grid item xs>
            <Button
              variant='outlined'
              color='secondary'
              onClick={(e: any) => {
                setManageAnchorEl(e.currentTarget)
              }}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                width: 140,
                height: 36,
                margin: '0px 12px auto auto',
                display: 'flex'
              }}
            >
              Manage
            </Button>
            {showInviteOption ? (
              <Menu
                anchorEl={manageAnchorEl}
                setAnchorEl={setManageAnchorEl}
                items={[
                  {
                    label: 'Invite staff',
                    onClick: () => {
                      setInviteDialogOpen(true)
                    },
                    icon: <AddIcon />
                  },
                  {
                    label: 'Edit permissions',
                    onClick: () => {
                      setIsInEditMode(true)
                    },
                    icon: <EditIcon />
                  }
                ]}
              />
            ) : (
              <Menu
                anchorEl={manageAnchorEl}
                setAnchorEl={setManageAnchorEl}
                items={[
                  {
                    label: 'Edit permissions',
                    onClick: () => {
                      setIsInEditMode(true)
                    },
                    icon: <EditIcon />
                  }
                ]}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TableTitle
