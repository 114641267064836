import { gql } from '@apollo/client'

const responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    base64
  }
`

const categoryContentFragment = `
  id
  title
  blurb
  slug
  minutes
  contentBucket {
    slug
  }
  category {
    slug
  }
  tags
  image {
    responsiveImage(imgixParams:{fm: jpg, fit: crop, w: 1100, h: 700}) {
      srcSet
      webpSrcSet
      sizes
      src
      width
      height
      aspectRatio
      alt
      title
      base64
    }
  }
`

const chapterRecordFragment = `
  fragment chapterRecordFragment on ChapterRecord {
    ${categoryContentFragment}
  }
`

const eventRecordFragment = `
  fragment eventRecordFragment on EventRecord {
    externalUrl
    startDate
    onDemand
    ${categoryContentFragment}
  }
`

const howToRecordFragment = `
  fragment howToRecordFragment on HowToRecord {
    ${categoryContentFragment}
  }
`

const interviewRecordFragment = `
  fragment interviewRecordFragment on InterviewRecord {
    ${categoryContentFragment}
  }
`

const toolkitRecordFragment = `
  fragment toolkitRecordFragment on ToolkitRecord {
    ${categoryContentFragment}
  }
`

const videoRecordFragment = `
  fragment videoRecordFragment on VideoRecord {
    ${categoryContentFragment}
  }
`

export const GET_DASHBOARD = gql`
  query HomePage {
    homePage {
      fallbackImage {
        responsiveImage(imgixParams: { fm: jpg, fit: crop, w: 1460, h: 822 }) {
          ...responsiveImageFragment
        }
      }
      articleFallbackImage {
        responsiveImage(imgixParams: { fm: jpg, fit: crop, w: 1460, h: 822 }) {
          ...responsiveImageFragment
        }
      }
      featured {
        __typename
        ... on VideoRecord {
          heroTitle
          heroSubtitle
          heroTextColor {
            hex
          }
          externalVideo {
            url
          }
          video {
            video {
              mp4Url
            }
          }
          image {
            responsiveImage(
              imgixParams: { fm: jpg, fit: crop, w: 1460, h: 822 }
            ) {
              srcSet
              webpSrcSet
              sizes
              src
              width
              height
              aspectRatio
              alt
              title
              base64
            }
          }
        }
      }
    }
    allEvents(orderBy: [startDate_ASC]) {
      ...eventRecordFragment
    }
    allCategories {
      __typename
      ... on CategoryRecord {
        name
        slug
        blurb
        featuredArticles {
          __typename
          ... on ChapterRecord {
            ...chapterRecordFragment
          }
          ... on EventRecord {
            ...eventRecordFragment
          }
          ... on InterviewRecord {
            ...interviewRecordFragment
          }
          ... on HowToRecord {
            ...howToRecordFragment
          }
          ... on ToolkitRecord {
            ...toolkitRecordFragment
          }
          ... on VideoRecord {
            ...videoRecordFragment
          }
          ... on VideoRecord {
            ...videoRecordFragment
          }
        }
      }
    }
  }
  ${chapterRecordFragment}
  ${eventRecordFragment}
  ${howToRecordFragment}
  ${interviewRecordFragment}
  ${toolkitRecordFragment}
  ${videoRecordFragment}
  ${responsiveImageFragment}
`

export const GET_DASHBOARD_NUMS = gql`
  query {
    numCandidatesAddedInLastWeek
    numCandidatesAddedSinceStartOfMonth
    totalBatchSizeRequestedSinceStartOfMonthCount
    completedBatchesSinceStartOfMonthCount
    myEmployer {
      monthlyCreditsAllowance
    }
  }
`
