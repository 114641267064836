import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import { DeleteDepartmentResponse, Department } from 'model/department'
import {
  CREATE_DEPARTMENT,
  GET_DEPARTMENTS,
  DELETE_MEMBERSHIP_BY_DEPARTMENT_ID,
  DELETE_MEMBERSHIP_BY_USER_ID,
  DELETE_DEPARTMENT
} from './gql'

const useCreateDepartment = () => {
  const { maestroClient } = useContext(MicroServiceContext)
  const [createdDepartment, setCreatedDepartment] = useState<
    Department | undefined
  >(undefined)
  const [createDepartment, { data, loading, error }] = useMutation(
    CREATE_DEPARTMENT,
    {
      client: maestroClient
    }
  )

  useEffect(() => {
    if (data) {
      setCreatedDepartment(data?.createDepartment)
    }
  }, [data])

  return {
    createDepartment,
    createdDepartmentIsLoading: loading,
    createdDepartment,
    error
  }
}

const useGetDepartments = () => {
  const { maestroClient, currentAccount } = useContext(MicroServiceContext)
  const [departments, setDepartments] = useState<Department[]>([])
  const { data, loading } = useQuery(GET_DEPARTMENTS, {
    client: maestroClient,
    variables: { epId: currentAccount.employer.id }
  })

  useEffect(() => {
    if (data) {
      setDepartments(data?.departments)
    }
  }, [data])
  return {
    departments,
    departmentsAreLoading: loading
  }
}

const useDeleteDepartmentMembershipsByDepartmentId = () => {
  const { maestroClient } = useContext(MicroServiceContext)
  const [deleteMembershipsResponse, setDeleteMembershipsResponse] = useState<
    Department[]
  >([])
  const [deleteMembershipByDepartmentId, { data, loading, error }] =
    useMutation(DELETE_MEMBERSHIP_BY_DEPARTMENT_ID, {
      client: maestroClient
    })

  useEffect(() => {
    if (data) {
      setDeleteMembershipsResponse(data?.deleteDepartmentMemberships)
    }
  }, [data])

  return {
    deleteMembershipByDepartmentId,
    deleteMembershipsResponse,
    updatedMembershipsAreLoading: loading,
    error
  }
}

const useDeleteDepartmentMembershipsByUserId = () => {
  const { maestroClient } = useContext(MicroServiceContext)
  const [deleteMembershipByUserResponse, setDeleteMembershipByUserResponse] =
    useState<Department[]>([])
  const [deleteMembershipByUserId, { data, loading, error }] = useMutation(
    DELETE_MEMBERSHIP_BY_USER_ID,
    {
      client: maestroClient
    }
  )

  useEffect(() => {
    if (data) {
      setDeleteMembershipByUserResponse(data?.deleteDepartmentMemberships)
    }
  }, [data])

  return {
    deleteMembershipByUserId,
    deleteMembershipByUserResponse,
    updatedMembershipsByUserAreLoading: loading,
    error
  }
}

const useDeleteDepartment = () => {
  const { maestroClient } = useContext(MicroServiceContext)
  const [deletedDepartment, setDeletedDepartment] = useState<
    DeleteDepartmentResponse | undefined
  >(undefined)
  const [deleteDepartment, { data, loading, error }] = useMutation(
    DELETE_DEPARTMENT,
    {
      client: maestroClient
    }
  )

  useEffect(() => {
    if (data) {
      setDeletedDepartment(data?.deleteDepartment)
    }
  }, [data])

  return {
    deleteDepartment,
    deletedDepartment,
    deletedDepartmentIsLoading: loading,
    error
  }
}

export default {
  useCreateDepartment,
  useGetDepartments,
  useDeleteDepartmentMembershipsByDepartmentId,
  useDeleteDepartmentMembershipsByUserId,
  useDeleteDepartment
}
