/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import { ApolloClient } from '@apollo/client'
import { CurrentAccount } from 'model/organization'
import React, { ReactNode } from 'react'

export const MicroServiceContext = React.createContext<any>({})
export interface MicroServiceProps {
  children: ReactNode
  currentAccount: CurrentAccount
  displayNotification: (bool: boolean) => void
  cmsApiKey: string
  apolloClient: any
  isFlagEnabled: (flagName: string) => boolean | undefined
  maestroClient: ApolloClient<any>
  openContactDialog: () => void
  setHideHeader: (bool: boolean) => void
}
export const MicroServiceProvider = (props: any): JSX.Element => {
  return (
    <MicroServiceContext.Provider value={props}>
      {props.children}
    </MicroServiceContext.Provider>
  )
}
