import { Box, Button, Divider, Grid, Typography, styled } from '@mui/material/'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'

const CustomTypography = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  width: 100%;
  margin-top: 24px;
`
const Container = styled(Box)`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  height: 368px;
  padding: 24px;
  padding-bottom: 45px;
`

const Departments = () => {
  return (
    <Container>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '24px',
          marginTop: '24px'
        }}
      >
        Departments
      </Typography>
      <CustomTypography>
        Add, manage, and customize your departments to enhance benchmarking,
        assign goals, <br />
        and filter DEI metrics.
      </CustomTypography>
      <Grid item xs={12}>
        <Divider flexItem sx={{ marginBottom: '24px', marginTop: '40px' }} />
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              fontWeight: 700,
              padding: '40px'
            }}
          >
            No departments added
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider flexItem sx={{ marginBottom: '24px', marginTop: '40px' }} />
      </Grid>
      <Button
        sx={{
          textDecorationLine: 'underline',
          fontWeight: 700,
          marginLeft: '-20px'
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>Add department</Typography>
        <AddIcon sx={{ marginLeft: '17px' }} />
      </Button>
    </Container>
  )
}

export default Departments
