import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  main: {
    minHeight: 'calc(100vh - 250px)'
  },
  dashboardContainer: {
    width: '90%',
    margin: 'auto',
    marginTop: 56,
    maxWidth: 1460
  }
}))
export default useStyles
