import { PaginationState } from 'model'

export enum FilterIndex {
  roles = 'roles',
  statuses = 'statuses'
}
export const INITIA_PAGINATION_STATE: PaginationState = {
  currentPage: 0,
  rowsPerPage: 10,
  cursor: undefined
}

export const TABLE = {
  TABLE_HEADS: [
    {
      id: 'name',
      label: 'User',
      width: '25%'
    },
    {
      id: 'email',
      label: 'Email',
      width: '20%'
    },
    {
      id: 'role',
      label: 'Role',
      width: '15%'
    },

    {
      id: 'EI_access',
      label: 'EI access',
      width: '20%'
    },

    {
      id: 'action',
      label: '',
      width: '10%'
    }
  ]
}

export const TABLE_NO_JOBS = {
  TABLE_HEADS: [
    {
      id: 'name',
      label: 'User',
      width: '25%'
    },
    {
      id: 'email',
      label: 'Email',
      width: '20%'
    },
    {
      id: 'role',
      label: 'Role',
      width: '15%'
    },
    {
      id: 'EI_access',
      label: 'EI access',
      width: '20%'
    },

    {
      id: 'action',
      label: '',
      width: '10%'
    }
  ]
}
