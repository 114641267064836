import React, { useState, useContext } from 'react'
import CategoryTabs from 'components/CMS/CategoryTabs'
import { Grid, Typography } from '@mui/material'
import { DashboardContext } from 'context/DashboardContext'
import { constructLandingPageTabsContent } from 'hooks/cmsComponentHooks'

const CategoryContents = () => {
  const { allCategories, homePage } = useContext(DashboardContext)

  const [value, setValue] = useState(0)

  const tabsContent = constructLandingPageTabsContent(allCategories)

  return (
    <>
      <Typography
        variant='h3'
        component='div'
        letterSpacing='0.1px'
        marginTop='72px'
        marginBottom='30px'
        fontSize='28px'
      >
        Learn & Cultivate
      </Typography>
      <Grid container flexDirection='column' alignItems='center'>
        <CategoryTabs
          tabsContent={tabsContent}
          fallbackImage={homePage.articleFallbackImage}
          value={value}
          setValue={setValue}
        />
      </Grid>
    </>
  )
}

export default CategoryContents
