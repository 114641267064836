import React, { memo } from 'react'
import useStyles from './style'
import { toCapitalizeWithSpace } from 'utils/stringHelper'
import { FormControl, Select, MenuItem } from '@mui/material'

interface Props {
  selected: any
  select: (selected: any) => void
  options: any[]
}

const MoveSelector = ({
  selected,
  select,
  options
}: Props): React.ReactElement => {
  const classes = useStyles()

  return (
    <FormControl
      fullWidth
      className={classes.selectContainer}
      sx={{ width: '85%' }}
    >
      <Select
        value={selected}
        displayEmpty
        className={classes.select}
        renderValue={newSelected => {
          return toCapitalizeWithSpace(newSelected)
        }}
        MenuProps={{
          classes: {
            paper: classes.selectMenuItem,
            list: classes.selectMenuList
          },
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        inputProps={{ sx: { padding: '0px 14px' } }}
      >
        {options.map((option: any) => {
          return (
            <MenuItem
              value={option}
              onClick={() => select(option)}
              className={classes.menuItemfont}
            >
              {toCapitalizeWithSpace(option)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default memo(MoveSelector)
