/* eslint-disable react/require-default-props */
import React from 'react'
import { Snackbar, SnackbarProps, Typography, styled } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Color } from '@mathison-inc/components'

export interface Props {
  open: boolean
  content: string
  onClose: () => void
  onUndo?: () => void
}

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  backgroundColor: '#050B0E',
  color: 'white',
  borderRadius: '8px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)',
  padding: '12px 17px',
  marginTop: '75px'
}))

const CustomSnackbar = (props: Props) => {
  const { open, onClose, content, onUndo } = props
  const undoExist = onUndo && onUndo?.name !== 'actionHandler'
  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          {content}
          <ClearIcon
            onClick={() => {
              onClose()
            }}
            sx={{
              marginLeft: '20px',
              fontSize: '16px',
              '&:hover': { cursor: 'pointer' }
            }}
          />
        </Typography>
        {undoExist && (
          <Typography
            color={Color.blue300}
            sx={{
              textDecorationLine: 'underline',
              textTransform: 'uppercase',
              fontWeight: 500,
              fontFamily: 'IBM Plex Sans',
              fontSize: 14,
              paddingRight: 12,
              cursor: 'pointer'
            }}
            onClick={() => {
              onUndo!()
              onClose()
            }}
          >
            UNDO
          </Typography>
        )}
      </div>
    </StyledSnackbar>
  )
}

export default CustomSnackbar
