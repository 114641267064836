import React, { useState, useContext, useCallback } from 'react'
import { Table } from 'components'
import TableContextProvider from 'context/TableContext'
import { StaffAccountContext } from 'context/StaffAccountProvider'
import TableRowBuilder from './TableRowBuilder'
import { TABLE } from './constants'
import TableFilters from './TableFilters'
import TableTitle from './TableTitle'

const StaffMember = () => {
  const [isInEditMode, setIsInEditMode] = useState(false)

  const {
    employerStaffAccounts,
    totalCount,
    loading,
    paginationState,
    filterState,
    dispatchFilter,
    dispatchPaginationState
  } = useContext(StaffAccountContext)
  const secondaryTitle = useCallback(
    () => (
      <TableTitle
        isInEditMode={isInEditMode}
        setIsInEditMode={setIsInEditMode}
      />
    ),
    [isInEditMode]
  )
  return (
    <TableContextProvider
      searchBoxPlaceholder='Search by user name or email'
      emptyTabelText='Try editing your search or filters.'
      emptyTableTitle='No results'
      tableHeads={TABLE.TABLE_HEADS}
      tableTitle=''
      TableRowBuilder={(props: any) => (
        <TableRowBuilder {...props} isInEditMode={isInEditMode} />
      )}
    >
      <Table
        loading={loading || employerStaffAccounts === undefined}
        filterState={filterState}
        sx={{ padding: '32px' }}
        data={employerStaffAccounts?.slice(
          paginationState.currentPage * paginationState.rowsPerPage,
          paginationState.currentPage * paginationState.rowsPerPage +
            paginationState.rowsPerPage
        )}
        dispatch={dispatchFilter}
        SecondaryFilters={TableFilters}
        SecondaryTitle={secondaryTitle}
        count={totalCount ?? 0}
        paginationState={paginationState}
        setPaginationState={dispatchPaginationState}
      />
    </TableContextProvider>
  )
}

export default StaffMember
