export type Answer = {
  id: string
  answerText: string
  order: string
  hasFollowupQuestion: boolean
  followupQuestionIds: string[] | null
  hasWeight?: boolean
}

export enum QuestionType {
  SINGLE_SELECT = 'single_select',
  MULTI_SELECT = 'multi_select'
}

export type Question = {
  id: string
  assessmentQuestionId?: string
  employerFollowupQuestions: Question[]
  questionText: string
  description: string
  questionType: QuestionType | string
  order: number
  subOrder?: number | null
  isCompleted?: boolean
  selectedAnswerIds: string[]
  assessmentAnswers: Answer[]

  __typename?: string
}

export type Category = {
  id: string
  quarter?: number
  year?: number
  categoryId?: string
  name: string
  description?: string
  totalScore?: number
  acquiredScore?: number
  progress?: number
  slug: string
  Questions?: Question[]
  isCompleted?: boolean
  employerAssessmentQuestions?: Question[]
  __typename?: string
}

export type EmployerQuarterHistory = {
  id: string
  acquiredScore: number
  endAt?: string
  employerAssessmentCategories: Category[]
}

export interface QuarterlyAssessmentHistory {
  firstQuarter: EmployerQuarterHistory | null
  secondQuarter: EmployerQuarterHistory | null
  thirdQuarter: EmployerQuarterHistory | null
  fourthQuarter: EmployerQuarterHistory | null
}

export interface EmployerAssessmentHistory extends QuarterlyAssessmentHistory {
  latestAssessment?: EmployerQuarterHistory | null
}

export enum Quarter {
  'firstQuarter',
  'secondQuarter',
  'thirdQuarter',
  'fourthQuarter'
}

export type AssessmentQuarter = {
  quarter: number
  year: number
  assessmentCompleted?: boolean
}

export type CurrentEpAssessment = {
  quarter: number
  year: number
  quarterAssessment: EmployerQuarterHistory | null | undefined
}

// Using numbers for keys (signifying the position in the graph) to map the quarter to the position in getLastFourQuarters method
export type CurrentEpAssessments = {
  1: CurrentEpAssessment | null
  2: CurrentEpAssessment | null
  3: CurrentEpAssessment | null
  4: CurrentEpAssessment | null
}

export enum PhaseState {
  all = 'all',
  current = 'current',
  later = 'later',
  inactive = 'inactive',
  completed = 'completed'
}

export interface GoalNode {
  cursor: string
  node: Goal
}

export interface Goal {
  activeStatus: string
  assessmentQuestion: string
  assessmentVersion: string
  category: string
  customTitle: string
  description: string
  employerId: string
  endDate: string
  goalId: number
  id: string
  kcUrls: string[]
  members: Member[]
  onTrack: string
  owner: Member
  phase: PhaseState
  scoreDelta: number
  startDate: string
  updatedAt: string
  title: string
  note: string
  isCompleted: boolean
  completedAt: string
}

export type Member = {
  id: string
  name: string
  primaryEmail: string
}
