import React from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { CmsArticle } from 'model/cms'
import { getLinkUrl } from 'hooks/cmsComponentHooks'
import { Box, BoxProps, Grid, GridProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import ArticleCard from '../ArticleCard'
import { theme } from '../../index'

interface CardCarouselProps {
  contentArray: CmsArticle[]
}

const SliderWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '5px 0',
  position: 'relative',
  margin: '20px 0 10px 0',
  '& .keen-slider__slide': {
    minWidth: '100% !important',
    maxWidth: '100% !important'
  }
}))

const ButtonWrapper = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'right',
  padding: 20
}))

const ArrowButton = styled(IconButton)<IconButtonProps>(() => ({
  backgroundColor: '#FFFFFF',
  color: '#000000',
  margin: '0 5px',
  '&:disabled': {
    backgroundColor: '#EAEAEA'
  }
}))

const StyledGridItem = styled(Grid)<GridProps>(() => ({
  '&.spacing-4': {
    '& .content-container': {
      p: {
        height: '110px',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis ellipsis',
        whiteSpace: 'break-spaces'
      }
    }
  },
  '& .article-card': {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    }
  }
}))

const createSlidePages = (_cards: CmsArticle[]): CmsArticle[][] => {
  const slides = []
  const cards = [..._cards]

  // Sort cards so that those that are NOT on-demand are first
  cards.sort((a, b) => Number(a.onDemand) - Number(b.onDemand))

  while (cards.length) slides.push(cards.splice(0, 2))
  return slides
}

const CardCarousel = ({ contentArray }: CardCarouselProps) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider]: any = useKeenSlider({
    initial: 0,
    slideChanged(slide) {
      setCurrentSlide(slide.track.details.rel)
    }
  })

  const slides = createSlidePages(contentArray)

  return (
    <Box sx={{ padding: '5px 0', width: '100%' }}>
      <SliderWrapper ref={sliderRef} className='keen-slider'>
        {slides.map((contentCard: CmsArticle[], index: number) => {
          return (
            <div
              key={`carousel-slide-${index + 1}`}
              className={`keen-slider__slide number-slide${index + 1}`}
            >
              <Grid container columnSpacing={3}>
                {contentCard.map((categoryContent: CmsArticle, idx: number) => (
                  <StyledGridItem
                    className='spacing-6'
                    key={`carousel-card-${idx + 1}`}
                    item
                    xs={6}
                  >
                    <ArticleCard
                      articleVariant='content'
                      cardGroup='two'
                      url={
                        categoryContent.externalUrl ||
                        getLinkUrl(
                          categoryContent.slug,
                          categoryContent.contentBucket,
                          categoryContent.category
                        )
                      }
                      onDemand={categoryContent.onDemand}
                      title={categoryContent.title}
                      blurb={categoryContent.blurb}
                      minutes={categoryContent.minutes}
                      startDate={categoryContent.startDate}
                      contentBucket={categoryContent.contentBucket}
                      category={categoryContent.category}
                      tags={categoryContent.tags}
                    />
                  </StyledGridItem>
                ))}
              </Grid>
            </div>
          )
        })}
      </SliderWrapper>

      {slides.length > 1 && (
        <ButtonWrapper>
          <ArrowButton
            onClick={(e: any) => e.stopPropagation() || slider.current?.prev()}
            disabled={currentSlide === 0}
          >
            <KeyboardArrowLeft />
          </ArrowButton>
          <ArrowButton
            onClick={(e: any) => e.stopPropagation() || slider.current?.next()}
            disabled={currentSlide === contentArray.length - 1}
          >
            <KeyboardArrowRight />
          </ArrowButton>
        </ButtonWrapper>
      )}
    </Box>
  )
}
export default CardCarousel
