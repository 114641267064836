import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

interface IProps {
  containerHeight: number | undefined
}

const LoadingCircular = ({ containerHeight }: IProps) => (
  <Grid
    sx={{ height: containerHeight ? `${containerHeight}px` : '100px' }}
    item
    flexGrow={1}
    container
    alignItems='center'
    justifyContent='center'
  >
    <CircularProgress size={40} thickness={4} />
  </Grid>
)

export default LoadingCircular
