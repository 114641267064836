import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Typography, Grid, Chip, Button, CircularProgress } from '@mui/material'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { Color, DialogWithoutAction } from 'components'
import { MergeContext } from 'context/mergeContext'
import useMerge from 'hooks/useMerge'
import { ATS_INTEGRATION } from './constants'
import useStyles from './style'

interface Props {
  employerName: String
}

const AtsIntegration = ({ employerName }: Props): ReactElement => {
  const classes = useStyles()
  const {
    linkedAccount,
    deleteLinkedAccount,
    linkedAccountLoading,
    createLinkedAccount
  } = useContext(MergeContext)
  const { getLinkToken, linkToken } = useMerge()
  useEffect(() => {
    getLinkToken({
      variables: {
        organizationName: employerName,
        category: 'ats'
      }
    })
  }, [employerName, getLinkToken])

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const onMergeSuccess = useCallback(
    (publicToken: String) => {
      createLinkedAccount({ variables: { publicToken } })
    },
    [createLinkedAccount]
  )
  const mergeConfig = {
    linkToken: linkToken?.linkToken,
    onSuccess: (public_token: String) => onMergeSuccess(public_token),
    onExit: () => console.log('Something bad happened here')
  }

  const { open: openMerge } = useMergeLink(mergeConfig)

  const closeDialog = () => {
    setOpenConfirmDialog(false)
  }

  return (
    <>
      <DialogWithoutAction
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        title='Are you sure you want to disconnect your HRIS?'
        content='You will have to go through the set up process again if you decide to reconnect.'
        Action={
          <>
            <Button
              onClick={() => {
                setOpenConfirmDialog(false)
              }}
              variant='contained'
              color='secondary'
              className={classes.dialogButtons}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                deleteLinkedAccount()
                closeDialog()
              }}
              sx={{ marginLeft: '16px' }}
              color='primary'
              variant='contained'
            >
              Disconnect
            </Button>
          </>
        }
      />
      <Grid item xs={12} container direction='row' alignItems='center'>
        <Typography variant='h3' color={Color.black}>
          Applicant Tracking System (ATS) integration
        </Typography>
        <Chip
          label='Beta'
          size='small'
          style={{
            marginLeft: '8px',
            backgroundColor: '#FCF4CC',
            color: '#395260'
          }}
        />
      </Grid>
      {linkedAccountLoading ? (
        <div style={{ margin: '24px' }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid item xs={12}>
          <div className={classes.loginSetting}>
            <Typography
              variant='body1Medium'
              color={Color.black80}
              className={classes.loginSettingTitle}
              paragraph
            />
            <Typography variant='body2' color={Color.black60}>
              {ATS_INTEGRATION.subtitle}
            </Typography>
            <Grid container direction='row' alignItems='center'>
              <Grid item xs={6}>
                <Grid container>
                  {linkedAccount ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#217D47',
                        width: 16.25
                      }}
                    />
                  ) : (
                    <DoDisturbIcon
                      sx={{
                        color: '#373C3F',
                        width: 16.25
                      }}
                    />
                  )}

                  <Typography style={{ paddingLeft: '8.80px' }}>
                    {linkedAccount
                      ? `ATS connected: ${linkedAccount.integrationName}`
                      : 'ATS not connected'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs>
                {linkedAccount ? (
                  <Typography
                    style={{
                      lineHeight: '18px',
                      textAlign: 'right',
                      paddingBottom: '6px',
                      marginTop: '20px'
                    }}
                    variant='body2Medium'
                    color={Color.main}
                    paragraph
                    className={classes.clickableText}
                    onClick={() => {
                      setOpenConfirmDialog(true)
                    }}
                  >
                    Disconnect my ATS
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
    </>
  )
}

export default AtsIntegration
