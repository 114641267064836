import { gql } from '@apollo/client'

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      id
      epId
      name
      description
      departmentType
      createdAt
      updatedAt
      members {
        id
        departmentId
        memberUserId
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_DEPARTMENTS = gql`
  query GetDepartments($ids: [ID!], $name: String, $epId: ID) {
    departments(ids: $ids, name: $name, epId: $epId) {
      id
      epId
      name
      description
      departmentType
      createdAt
      updatedAt
      members {
        id
        departmentId
        memberUserId
        createdAt
        updatedAt
      }
    }
  }
`
export const DELETE_MEMBERSHIP_BY_DEPARTMENT_ID = gql`
  mutation DeleteDepartmentMembership(
    $input: DeleteDepartmentMembershipsInput!
  ) {
    deleteDepartmentMemberships(input: $input) {
      id
      departmentId
      createdAt
      updatedAt
      department {
        id
        epId
        name
        description
        departmentType
        createdAt
        updatedAt
      }
    }
  }
`

export const DELETE_MEMBERSHIP_BY_USER_ID = gql`
  mutation DeleteDepartmentMembership(
    $input: DeleteDepartmentMembershipsInput!
  ) {
    deleteDepartmentMemberships(input: $input) {
      id
      departmentId
      createdAt
      updatedAt
      department {
        id
        epId
        name
        description
        departmentType
        createdAt
        updatedAt
      }
    }
  }
`

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($input: DeleteDepartmentInput!) {
    deleteDepartment(input: $input) {
      id
      departmentType
      createdAt
      updatedAt
    }
  }
`
