import React, { useContext, useState } from 'react'
import { Alert, Divider, Grid, Typography } from '@mui/material'
import { Color, Paper } from 'components'
import { MicroServiceContext } from 'context/MicroService'
import { AlertContext } from 'context/AlertContext'
import { MergeProvider } from 'context/mergeContext'
import useAuthenticationSettings from 'hooks/useAuthenticationSettings'
import AtsIntegration from './AtsIntegration'

import useStyles from './style'
import HRISIntegration from './HRISIntegration'
import { LOGIN_STRATEGY } from './constants'
import Dialog from './Dialog'

const Overview = ({ myEmployer: employer }: any) => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)

  const { currentAccount, isFlagEnabled } = useContext(MicroServiceContext)

  const { displayAlertMessage } = useContext(AlertContext)

  const showATSIntegration: boolean = isFlagEnabled('ats-pilot')
  const enableAuthSettings: boolean = isFlagEnabled('enable-auth-settings')

  const {
    employerAuthenticationSettings,
    updateEmployerAuthenticationSettings
  } = useAuthenticationSettings()
  if (!employerAuthenticationSettings) return null
  return (
    <Paper className={classes.overviewContainer} sx={{ padding: '24px' }}>
      {showATSIntegration && (
        <>
          <MergeProvider category='ats'>
            {showATSIntegration && (
              <AtsIntegration employerName={employer?.name} />
            )}
          </MergeProvider>
          <Grid item xs={12}>
            <Divider flexItem sx={{ marginBottom: '24px' }} />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant='h3' color={Color.black}>
          Log in settings
        </Typography>
        {!enableAuthSettings && (
          <Alert
            sx={{
              maxWidth: '750px',
              marginTop: '20px',
              backgroundColor: '#F0E8FF',
              padding: '10px 15px',
              borderRadius: '8px',
              color: Color.main,
              '.MuiAlert-icon': {
                color: '#420CA4',
                marginTop: '10px'
              }
            }}
            severity='warning'
          >
            <Typography
              variant='body1'
              sx={{ fontSize: '15px', lineHeight: '20px' }}
            >
              The ability to edit your authentication settings is under
              construction. Please reach out to{' '}
              <a
                style={{
                  fontSize: '15px',
                  lineHeight: '20px',
                  display: 'inline-block',
                  textDecoration: 'underline'
                }}
                href='mailto:support@mathison.io'
              >
                support@mathison.io
              </a>{' '}
              if you have any questions or would like to change your
              authentication settings.
            </Typography>
          </Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.loginSetting}>
          <Typography
            variant='body1Medium'
            color={Color.black80}
            className={classes.loginSettingTitle}
            paragraph
          >
            {employerAuthenticationSettings.strategy &&
              (LOGIN_STRATEGY as any)[employerAuthenticationSettings.strategy]
                .title}
          </Typography>
          <Typography variant='body2' color={Color.black60}>
            {employerAuthenticationSettings.strategy &&
              (LOGIN_STRATEGY as any)[employerAuthenticationSettings.strategy]
                .subtitle}
          </Typography>
          {enableAuthSettings ? (
            <Typography
              variant='body2Medium'
              color={Color.main}
              paragraph
              className={classes.clickableText}
              margin='12px auto 0px 0px'
              onClick={() => {
                if (employer.authenticationStatus !== 'FAILED') {
                  setOpenDialog(true)
                } else {
                  displayAlertMessage('Please contact your impact manager!')
                }
              }}
            >
              Edit
            </Typography>
          ) : (
            <Typography
              variant='body2Medium'
              color={Color.grey500}
              paragraph
              className={classes.unclickableText}
              margin='12px auto 0px 0px'
            >
              Edit
            </Typography>
          )}

          <Dialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            authenticationSettings={employerAuthenticationSettings}
            updateEmployerAuthenticationSettings={
              updateEmployerAuthenticationSettings
            }
          />
        </div>
      </Grid>
      <MergeProvider category='hris'>
        <HRISIntegration currentAccount={currentAccount} />
      </MergeProvider>
    </Paper>
  )
}
export default Overview
