import React, { useContext, useState } from 'react'
import {
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  Backdrop,
  IconButton,
  Grid
} from '@mui/material'
import { Color, UserAvatar } from 'components'
import { toCapitalizeWithSpace } from 'utils/stringHelper'
import { StaffAccount } from 'model'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { StaffAccountContext } from 'context/StaffAccountProvider'
import { EmployerContext } from 'context/EmployerProvider'
import {
  ROLE_OPTIONS,
  EHI_ACCESS_OPTIONS,
  EmployerStaffAccountRole,
  EmployerStaffAccountJobsAccessLevel,
  EmployerStaffAccountEhiAccessLevel
} from './constants'
import Selector from './Selector'
import StaffMenu from './StaffMenu'

interface Props {
  data: StaffAccount
  isInEditMode: boolean
}

const TableRowBuilder = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState()
  const { data, isInEditMode } = props

  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const { changePermission } = useContext(StaffAccountContext)
  const { myEmployer } = useContext(EmployerContext)
  const { id: employerId } = myEmployer
  const { id, roles, email, status, userId, fullName } = data

  const selectRole = async (value: string) => {
    if (value === EmployerStaffAccountRole.BASIC_USER) {
      const result = await changePermission({
        variables: {
          input: {
            epId: employerId,
            userId,
            roles: [
              EmployerStaffAccountRole.BASIC_USER,
              EmployerStaffAccountJobsAccessLevel.BASIC_JOBS_ACCESS,
              EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS
            ]
          }
        }
      })
      if (!result.data) {
        setIsBackdropOpen(false)
      }
    } else {
      const result = await changePermission({
        variables: {
          input: {
            epId: employerId,
            userId,
            roles: [
              value,
              EmployerStaffAccountJobsAccessLevel.JOBS_LEAD,
              EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS
            ]
          }
        }
      })
      if (!result.data) {
        setIsBackdropOpen(false)
      }
    }
  }

  const selectEHIAccess = async (value: string) => {
    const newRoles = [...roles]
    const index = newRoles.findIndex((role: any) =>
      Object.values(EmployerStaffAccountEhiAccessLevel).includes(role)
    )
    if (index !== -1) {
      newRoles[index] = value
    } else {
      newRoles.push(value)
    }

    const result = await changePermission({
      variables: {
        input: {
          epId: employerId,
          userId,
          roles: newRoles
        }
      }
    })
    if (!result.data) {
      setIsBackdropOpen(false)
    }
  }

  return (
    <>
      <StaffMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        userId={userId}
        selectedStaffAccountId={id}
        status={status}
      />
      <TableRow>
        <TableCell>
          <Grid container wrap='nowrap'>
            <UserAvatar username={fullName ?? email} />
            <Grid marginLeft={2} item>
              <Typography
                variant='body2Medium'
                color={Color.main}
                textOverflow='ellipsis'
              >
                {fullName ?? email ?? 'Unknown'}
              </Typography>
              <Typography
                variant='body2'
                lineHeight='20px'
                color={Color.black}
                fontWeight={300}
              >
                {toCapitalizeWithSpace(status) ?? 'Unknown'}
              </Typography>
            </Grid>
          </Grid>

          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={isBackdropOpen}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          {isInEditMode ? (
            <Selector
              options={ROLE_OPTIONS}
              select={selectRole}
              selected={
                roles.find((role: any) => ROLE_OPTIONS.includes(role)) ??
                'Unknown'
              }
            />
          ) : (
            <Typography variant='body2' color={Color.black80}>
              {toCapitalizeWithSpace(
                roles.find((role: any) => ROLE_OPTIONS.includes(role)) ??
                  'Unknown'
              )}
            </Typography>
          )}
        </TableCell>

        <TableCell align='left'>
          {isInEditMode ? (
            <Selector
              options={EHI_ACCESS_OPTIONS}
              select={selectEHIAccess}
              selected={
                roles.find((role: any) => EHI_ACCESS_OPTIONS.includes(role)) ??
                'Unknown'
              }
            />
          ) : (
            <Typography variant='body2' color={Color.black80}>
              {toCapitalizeWithSpace(
                roles.find((role: any) => EHI_ACCESS_OPTIONS.includes(role)) ??
                  'Unknown'
              )}
            </Typography>
          )}
        </TableCell>

        <TableCell
          align='right'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

export default TableRowBuilder
