import { gql, useQuery } from '@apollo/client'

const INITIAL_EMPLOYER = {
  myEmployer: {
    mainContact: { email: '', name: '', phone: '' },
    authenticationSettings: {
      domain: ''
    },
    id: '',
    name: '',
    impactManager: { email: '', id: '', name: '' }
  }
}

const GET_MY_EMPLOYER = gql`
  query {
    myEmployer {
      authenticationStatus
      code
      id
      impactManagerId
      insertedAt
      logoS3Key
      name
      status
      updatedAt
    }
  }
`

const useEmployer = () => {
  const { data: { myEmployer } = INITIAL_EMPLOYER } = useQuery(GET_MY_EMPLOYER)
  return { myEmployer }
}

export default useEmployer
