import React, { createContext, useEffect, useMemo, useState } from 'react'
import useDepartments from 'hooks/useDepartments'
import { DeleteDepartmentResponse, Department } from 'model/department'

// this is what we're telling the FE to look for
type DepartmentsData = {
  departments: Department[]
  createdDepartment: Department | undefined
  updatedMemberships: Department[]
  departmentsAreLoading: boolean
  createDepartment: Function
  createdDepartmentIsLoading: boolean
  deleteMembershipByDepartmentId: Function
  updatedMembershipsAreLoading: boolean
  deleteMembershipByUserId: Function
  updatedMembershipsByUserAreLoading: boolean
  deleteDepartment: Function
  deletedDepartment: DeleteDepartmentResponse | undefined
  deletedDepartmentIsLoading: boolean
}

// this models the type and this is actual data to send to react
export const DepartmentsContext = createContext<DepartmentsData>({
  departments: [],
  createdDepartment: undefined,
  updatedMemberships: [],
  departmentsAreLoading: true,
  createDepartment: () => undefined,
  createdDepartmentIsLoading: true,
  deleteMembershipByDepartmentId: () => undefined,
  updatedMembershipsAreLoading: true,
  deleteMembershipByUserId: () => undefined,
  updatedMembershipsByUserAreLoading: true,
  deleteDepartment: () => undefined,
  deletedDepartment: undefined,
  deletedDepartmentIsLoading: true
})

export const DepartmentsProvider = ({ children }: any) => {
  // Update Memberships - Department Object for deleting either by user id or department id
  const [updatedMemberships, setUpdatedMemberships] = useState<Department[]>([])

  // GET Departments
  const { departments, departmentsAreLoading } =
    useDepartments.useGetDepartments()

  // Create Department
  const { createdDepartment, createDepartment, createdDepartmentIsLoading } =
    useDepartments.useCreateDepartment()

  // Delete Department
  const { deletedDepartment, deleteDepartment, deletedDepartmentIsLoading } =
    useDepartments.useDeleteDepartment()

  // Delete Department Membership By Department ID
  const {
    deleteMembershipsResponse,
    deleteMembershipByDepartmentId,
    updatedMembershipsAreLoading
  } = useDepartments.useDeleteDepartmentMembershipsByDepartmentId()

  useEffect(() => {
    if (deleteMembershipsResponse) {
      setUpdatedMemberships(deleteMembershipsResponse)
    }
  }, [deleteMembershipsResponse])

  // Delete Department Membership By User ID
  const {
    deleteMembershipByUserId,
    deleteMembershipByUserResponse,
    updatedMembershipsByUserAreLoading
  } = useDepartments.useDeleteDepartmentMembershipsByUserId()

  useEffect(() => {
    if (deleteMembershipByUserResponse) {
      setUpdatedMemberships(deleteMembershipByUserResponse)
    }
  }, [deleteMembershipByUserResponse])

  // contract btw the context and the hook
  const departmentsData: DepartmentsData = useMemo(
    () => ({
      departments,
      createdDepartment,
      updatedMemberships,
      departmentsAreLoading,
      createDepartment,
      createdDepartmentIsLoading,
      deleteMembershipByDepartmentId,
      updatedMembershipsAreLoading,
      deleteMembershipByUserId,
      updatedMembershipsByUserAreLoading,
      deleteDepartment,
      deletedDepartment,
      deletedDepartmentIsLoading
    }),
    [
      departments,
      createdDepartment,
      updatedMemberships,
      departmentsAreLoading,
      createDepartment,
      createdDepartmentIsLoading,
      deleteMembershipByDepartmentId,
      updatedMembershipsAreLoading,
      deleteMembershipByUserId,
      updatedMembershipsByUserAreLoading,
      deleteDepartment,
      deletedDepartment,
      deletedDepartmentIsLoading
    ]
  )

  return (
    <DepartmentsContext.Provider value={departmentsData}>
      {children}
    </DepartmentsContext.Provider>
  )
}
