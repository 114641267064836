import * as React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { constructChapterLabel } from 'hooks/cmsComponentHooks'

interface IProps {
  chapterNumber: number
}

const StyledChip = styled(Chip)<ChipProps>(() => ({
  fontFamily: '"IBM Plex Sans", sans-serif',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '2px',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  textTransform: 'uppercase',
  padding: '0px 24px',
  height: '36px',
  borderRadius: '25px',
  '& span': {
    paddingLeft: 0
  }
}))

const ChapterChip = (props: IProps) => {
  const { chapterNumber } = props
  const label: string = constructChapterLabel(chapterNumber)
  return <StyledChip label={label} />
}

export default ChapterChip
