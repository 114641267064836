import useEmployer from 'hooks/useEmployer'
import React, { createContext, useMemo } from 'react'

// this is what we're telling the FE to look for
type EmployerData = {
  myEmployer: any
}

export const EmployerContext = createContext<EmployerData>({
  myEmployer: {}
})

export const EmployerProvider = ({ children }: any) => {
  const { myEmployer } = useEmployer()
  const value: EmployerData = useMemo(() => ({ myEmployer }), [myEmployer])

  return (
    <EmployerContext.Provider value={value}>
      {children}
    </EmployerContext.Provider>
  )
}
