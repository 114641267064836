import React, { useContext } from 'react'

import { Grid, Typography } from '@mui/material'
import { TakeActionProvider } from 'context/TakeActionContext'
import { DashboardProvider } from 'context/DashboardContext'
import { MicroServiceContext } from 'context/MicroService'
import useStyles from './style'
import StatisticsBlock from './StatisticsBlock'
import Events from './Events'
import CategoryContents from './CategoryContents'
import TakeAction from './TakeAction'

const Home = (): React.ReactElement => {
  const classes = useStyles()

  // TODO: Get "isFlagEnabled" and "flags" from context for LaunchDarkly
  const { currentAccount, cmsApiKey } = useContext(MicroServiceContext)

  if (!cmsApiKey) {
    return <></>
  }

  return (
    <DashboardProvider cmsApiKey={cmsApiKey}>
      <TakeActionProvider>
        <div className={classes.dashboardContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h1'>
                Welcome, {`${currentAccount.user.name.split(' ')[0]}`}
              </Typography>
            </Grid>
            <Grid item container>
              <TakeAction />
              <Events />
              <StatisticsBlock />
              <CategoryContents />
            </Grid>
          </Grid>
        </div>
      </TakeActionProvider>
    </DashboardProvider>
  )
}

export default Home
