/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext } from 'react'
import { CurrentEpAssessments, GoalNode } from 'model/takeAction'
import useTakeAction from 'hooks/useTakeAction'
import {
  getLastFourQuarters,
  getLatestAssessmentQuarter,
  getPreviousAndCurrentAssessments
} from 'utils/takeActionUtils'

type TakeActionContextType = {
  totalScore: number | undefined
  latestAssessmentYear: number | undefined
  latestAssessmentQuarter: number | undefined
  currentYearScore: number | undefined
  previousScore: number | undefined
  previousQuarter: number | undefined
  previousYear: number | undefined
  noAssessmentsCompleted: boolean
  assessmentDataIsLoading: boolean
  goalsAreLoading: boolean
  goals: GoalNode[]
  isFlagEnabled: (flagName: string) => boolean | undefined
}

export const TakeActionContext = createContext<TakeActionContextType>({
  totalScore: undefined,
  latestAssessmentYear: undefined,
  latestAssessmentQuarter: undefined,
  currentYearScore: undefined,
  previousScore: undefined,
  previousQuarter: undefined,
  previousYear: undefined,
  noAssessmentsCompleted: true,
  assessmentDataIsLoading: true,
  goalsAreLoading: true,
  goals: [],
  isFlagEnabled: () => false
})

export const TakeActionProvider = ({ children }: any): JSX.Element => {
  const {
    latestAssessment,
    currentYearAssessments,
    assessmentDataIsLoading,
    previousYearAssessments,
    goalsAreLoading,
    getRecentGoals,
    isFlagEnabled
  } = useTakeAction()

  const goals: GoalNode[] = getRecentGoals()

  // Get the current and previous assessment from currentYearAssessments and previousYearAssessments
  const currentEpAssessments: CurrentEpAssessments = getLastFourQuarters(
    previousYearAssessments?.employerAssessmentHistory,
    currentYearAssessments?.employerAssessmentHistory
  )

  const [previousAssessment, currentAssessment] =
    getPreviousAndCurrentAssessments(currentEpAssessments)

  // Construct data needed for TakeAction Component
  const latestAssessmentDate: string | undefined = latestAssessment?.endAt
  const latestAssessmentYear: number | undefined = latestAssessmentDate
    ? new Date(latestAssessmentDate).getFullYear()
    : undefined
  const latestAssessmentMonth: number | undefined = latestAssessmentDate
    ? new Date(latestAssessmentDate).getMonth()
    : undefined
  const latestAssessmentQuarter: number = getLatestAssessmentQuarter(
    latestAssessmentMonth
  )
  const previousAssessmentDate: string | undefined = previousAssessment?.endAt
  const previousYear: number | undefined = previousAssessmentDate
    ? new Date(previousAssessmentDate).getFullYear()
    : undefined
  const previousQuarter: number | undefined = previousAssessment?.quarter
  const previousScore: number | undefined = previousAssessment?.acquiredScore
  const currentYearScore: number | undefined = currentAssessment?.acquiredScore
  const totalScore: number | undefined = latestAssessment?.acquiredScore
  const noAssessmentsCompleted: boolean = !latestAssessment?.acquiredScore

  return (
    <TakeActionContext.Provider
      value={{
        totalScore,
        latestAssessmentYear,
        latestAssessmentQuarter,
        currentYearScore,
        previousScore,
        previousQuarter,
        previousYear,
        noAssessmentsCompleted,
        assessmentDataIsLoading,
        goalsAreLoading,
        goals,
        isFlagEnabled
      }}
    >
      {children}
    </TakeActionContext.Provider>
  )
}
