import { gql } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import { useLazyQuery, useMutation } from 'gql/hooks'
import { useContext } from 'react'

const LINKED_ACCOUNT = gql`
  query LinkedAccount($category: String!) {
    linkedAccount(category: $category) {
      id
      integrationName
      contactEmail
    }
  }
`

const LINK_TOKEN = gql`
  query LinkedToken($organizationName: String!, $category: String!) {
    linkToken(organizationName: $organizationName, category: $category)
  }
`

const DELETE_LINKED_ACCOUNT = gql`
  mutation DeleteLinkedAccount($category: String!) {
    deleteLinkedAccount(category: $category) {
      id
      integrationName
      contactEmail
    }
  }
`

const CREATE_LINKED_ACCOUNT = gql`
  mutation CreateLinkedAccount($publicToken: String!) {
    createLinkedAccount(publicToken: $publicToken) {
      organizationName
      integrationName
    }
  }
`

const useMerge = () => {
  const { maestroClient } = useContext(MicroServiceContext)
  const [
    getLinkedAccount,
    {
      data: linkedAccountData,
      error: linkedAccountError,
      loading: linkedAccountLoading
    }
  ] = useLazyQuery(LINKED_ACCOUNT, {
    client: maestroClient
  })

  const [onDeleteLinkedAccount] = useMutation(DELETE_LINKED_ACCOUNT, {
    client: maestroClient,
    successNotification: 'Integration disconnected'
  })
  const [getLinkToken, { data: linkToken }] = useLazyQuery(LINK_TOKEN, {
    client: maestroClient
  })
  const [createLinkedAccount] = useMutation(CREATE_LINKED_ACCOUNT, {
    client: maestroClient,
    // update the cache, this is required so changes are displayed immediately
    update(cache: any, { data: { linkedAccount } }: any): void {
      cache.modify({
        fields: {
          linkedAccount(): any {
            return linkedAccount
          }
        }
      })
    }
  })
  return {
    getLinkedAccount,
    linkedAccountData,
    linkedAccountError,
    onDeleteLinkedAccount,
    getLinkToken,
    linkToken,
    linkedAccountLoading,
    createLinkedAccount
  }
}

export default useMerge
