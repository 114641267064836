/* eslint-disable react/require-default-props */
import * as React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { constructEyebrowTimeLabel } from 'hooks/cmsComponentHooks'

interface TimeLabelChipProps {
  time: number
  onPage: boolean
  isVideo?: boolean
}

const StyledChip = styled(Chip)<ChipProps>(() => ({
  fontFamily: '"IBM Plex Sans", sans-serif',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '2px',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  textTransform: 'uppercase',
  height: '36px',
  borderRadius: '25px',
  '& span': {
    paddingLeft: '16px'
  }
}))

const ReadViewTimeChip = (props: TimeLabelChipProps) => {
  const { time, onPage, isVideo } = props
  const label: string = constructEyebrowTimeLabel(time, onPage, isVideo)
  return <StyledChip label={label} />
}
export default ReadViewTimeChip
