import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { GoalTag } from 'components'
import { GoalNode } from 'model/takeAction'
import { ArrowForward } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

interface IProps {
  goals: GoalNode[]
}

export const parseGoalCategory = (category: string) => {
  const letters = category?.split('_')
  return `${letters[0].charAt(0).toUpperCase()}&${letters[1]
    ?.charAt(0)
    .toUpperCase()}`
}

const RecentGoals = ({ goals }: IProps) => {
  const navigate = useNavigate()
  return (
    <Grid container>
      <Grid item xs={12} marginBottom='56px'>
        <Typography variant='h7' component='div' sx={{ marginTop: '26px' }}>
          RECENT GOALS
        </Typography>
      </Grid>
      {goals && goals.length > 0 ? (
        <>
          {goals.map((goalNode: GoalNode, index: number) => {
            const goal = goalNode.node
            // Check if customTitle has only white space characters
            const trimmedCustomTitle = goal.customTitle
              ? goal.customTitle.trim()
              : null

            return (
              <>
                <Grid
                  container
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/action-plan?id=${goal.id}`)
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: 68
                    }}
                  >
                    <GoalTag
                      sx={{
                        height: '24px'
                      }}
                      label={parseGoalCategory(goal.category)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{ ':hover': { textDecoration: 'underline' } }}
                  >
                    <Typography
                      variant='body18'
                      sx={{
                        display: '-webkit-box',
                        '-webkit-line-clamp': '3',
                        '-webkit-box-orient': 'vertical',
                        overflow: 'hidden'
                      }}
                    >
                      {goal.customTitle && trimmedCustomTitle
                        ? goal.customTitle
                        : goal.title}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ width: 60 }}>
                    <ArrowForward />
                  </Grid>
                </Grid>
                {index !== goals.length - 1 && (
                  <Divider flexItem sx={{ width: '100%', margin: '16px 0' }} />
                )}
              </>
            )
          })}
        </>
      ) : (
        <Typography variant='body1'>
          Already taken the Assessment? It may still be loading. Or, your DEI
          team hasn&rsquo;t saved any goals to the Action Plan just yet. Give it
          some time and check in soon.
        </Typography>
      )}
    </Grid>
  )
}
export default RecentGoals
