import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFound from 'pages/NotFound'
import Home from 'pages/Home'
import Organization from 'pages/Organization'
import { EmployerProvider } from 'context/EmployerProvider'

const ProtectedRoutes = (): ReactElement => {
  return (
    <Routes>
      <Route path='' element={<Home />} />
      <Route
        path='/organization-settings'
        element={
          <EmployerProvider>
            <Organization />
          </EmployerProvider>
        }
      />
      <Route path='/*' element={<NotFound />} />
    </Routes>
  )
}

export default ProtectedRoutes
