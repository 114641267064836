import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  description: { position: 'relative', bottom: 8 },
  indentContainer: { marginLeft: 28 },
  container: {
    padding: '40px 0',
    maxWidth: '1460px',
    width: '90%',
    margin: '0 auto'
  },
  tabs: { margin: '24px 0', width: '100%' },
  impactManagerContainer: { padding: 24 },
  pointer: { cursor: 'pointer' },
  '@global': {
    '.MuiTabs-flexContainer': {
      justifyContent: 'initial !important'
    }
  }
}))

export default useStyles
