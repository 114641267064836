export enum EmployerStaffAccountRole {
  BASIC_USER = 'BASIC_USER',
  MANAGER = 'MANAGER',
  SUPER_MANAGER = 'SUPER_MANAGER'
}
export enum EmployerStaffAccountJobsAccessLevel {
  BASIC_JOBS_ACCESS = 'BASIC_JOBS_ACCESS',
  JOBS_LEAD = 'JOBS_LEAD',
  NO_JOBS_ACCESS = 'NO_JOBS_ACCESS'
}
export enum EmployerStaffAccountEhiAccessLevel {
  FULL_EHI_ACCESS = 'FULL_EHI_ACCESS',
  BASIC_EHI_ACCESS = 'BASIC_EHI_ACCESS',
  NO_EHI_ACCESS = 'NO_EHI_ACCESS'
}
export enum EmployerStaffAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING'
}

export type Account = {
  cmsApiKey: string
  currentAccount: CurrentAccount
}
export type CurrentAccount = {
  id: string
  email: string
  employer: Employer
  user: User
  jobsAccess?: EmployerStaffAccountJobsAccessLevel
  role?: EmployerStaffAccountRole
  ehiAccess?: EmployerStaffAccountEhiAccessLevel
}
export type User = {
  id: string
  name: string
  primaryEmail: string
}

export type AuthenticationSettings = {
  domain?: string
  idpType?: IdpType
  strategy?: AccessMethod
}
export enum AccessMethod {
  DOMAIN_LOCK = 'DOMAIN_LOCK',
  INVITATION_ONLY = 'INVITATION_ONLY'
}
export enum IdpType {
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN',
  MICROSOFT = 'MICROSOFT'
}
export type Employer = {
  authenticationStatus?: EmployerAuthenticationStatus
  authenticationSettings?: AuthenticationSettings
  id?: string
  impactManager: Admin
  logo?: string
  mainContact?: MainContact
  name: string
  code?: string
  numAllocatedSeats?: number
  status?: EmployerActiveStatus
  numOpenSeats?: number
  numUsedSeats?: number
}
enum EmployerActiveStatus {
  ACTIVE,
  INACTIVE
}
type MainContact = {
  email: string
  name: string
  phone: string
}
export enum EmployerAuthenticationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED'
}
type Admin = {
  email: string
  id: string
  name: string
}
