/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { CmsArticle, CmsImage, TabContent } from 'model/cms'
import { TabPanel } from 'components'
import {
  Grid,
  Tabs,
  Tab,
  TabsProps,
  Box,
  BoxProps,
  Button,
  Typography,
  useMediaQuery
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { getLinkUrl } from 'hooks/cmsComponentHooks'
import { Link } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArticleCard from '../ArticleCard'

interface CategoryTabsProps {
  tabsContent: TabContent[]
  fallbackImage: CmsImage | undefined
  value: number
  setValue: (num: number) => void
}

const TabsContainer = styled(Box)<BoxProps>(() => ({
  width: '100%',
  margin: '40px 0'
}))

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  fontFamily: '"IBM Plex Sans", sans-serif',
  color: 'black',
  marginTop: '-35px',
  '.MuiButtonBase-root': {
    textTransform: 'none',
    fontWeight: 500,
    letterSpacing: ' -0.02em',
    width: '168px',
    height: '28px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.90rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  '.Mui-selected': {
    fontWeight: '700 !important'
  }
}))

const CategoryTabs = ({
  tabsContent,
  fallbackImage,
  value,
  setValue
}: CategoryTabsProps) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(Number(newValue))
  }
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1305))
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(1035))
  const hideIndicator = useMediaQuery(theme.breakpoints.down(1006))

  return (
    <TabsContainer>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant='fullWidth'
        textColor='inherit'
        aria-label='Category Tabs'
        sx={{
          '& .MuiTabs-flexContainer': {
            justifyContent: 'space-between',
            flexWrap: isSmallerScreen ? 'wrap' : 'nowrap',
            [theme.breakpoints.down(1006)]: {
              justifyContent: 'space-between'
            },
            '& .MuiTab-root': {
              maxWidth: isMediumScreen ? '100%' : 'none',
              fontSize: isMediumScreen ? '15px' : '20px'
            }
          },
          '.MuiTabs-indicator': {
            backgroundColor: hideIndicator
              ? 'transparent !important'
              : 'black !important'
          }
        }}
      >
        {tabsContent.map((tab: TabContent, index: number) => (
          <Tab
            key={`tab-${index + 1}`}
            label={tab.name}
            value={index}
            wrapped
          />
        ))}
      </StyledTabs>
      <>
        {tabsContent.map((tab: TabContent, index: number) => (
          <TabPanel
            sx={{ padding: 0 }}
            key={`panel-${index + 1}`}
            value={value}
            index={index}
          >
            <Grid
              sx={{ marginTop: '32px' }}
              container
              rowSpacing={3}
              columnSpacing={3}
            >
              {tab.featuredArticles && tab.featuredArticles.length > 0 ? (
                <>
                  {tab.featuredArticles.map(
                    (content: CmsArticle, index: number) => (
                      <React.Fragment key={`category-card-${index}`}>
                        {index === 0 ? (
                          <Grid item sm={12}>
                            <ArticleCard
                              articleVariant='featured'
                              url={
                                content.externalUrl ||
                                getLinkUrl(
                                  content.slug,
                                  content.contentBucket,
                                  content.category
                                )
                              }
                              title={content.title}
                              blurb={content.blurb}
                              minutes={content.minutes}
                              contentBucket={content.contentBucket}
                              category={content.category}
                              image={content.image ?? fallbackImage}
                              tags={content.tags}
                            />
                          </Grid>
                        ) : (
                          <>
                            {index < 3 ? (
                              <Grid item xs={12} lg={6}>
                                <ArticleCard
                                  articleVariant='content'
                                  url={
                                    content.externalUrl ||
                                    getLinkUrl(
                                      content.slug,
                                      content.contentBucket,
                                      content.category
                                    )
                                  }
                                  title={content.title}
                                  blurb={content.blurb}
                                  minutes={content.minutes}
                                  contentBucket={content.contentBucket}
                                  category={content.category}
                                  tags={content.tags}
                                />
                              </Grid>
                            ) : null}
                          </>
                        )}
                      </React.Fragment>
                    )
                  )}
                </>
              ) : null}
            </Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '40px',
                width: '100%'
              }}
            >
              <Box
                style={{
                  backgroundColor: '#FFF',
                  width: '-webkit-fill-available',
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px'
                }}
              >
                <Button
                  sx={{
                    width: 176,
                    height: 44,
                    marginBottom: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    marginLeft: 'auto',
                    marginTop: '20px',
                    marginRight: '27px'
                  }}
                  variant='contained'
                  color='primary'
                >
                  <Link to={`/knowledge-center/category/${tab.slug}/`}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '-5px'
                      }}
                    >
                      <Typography variant='h3' fontSize='16px' color='white'>
                        See all resources
                      </Typography>
                      <ArrowForwardIcon style={{ marginLeft: '7px' }} />
                    </div>
                  </Link>
                </Button>
              </Box>
            </div>
          </TabPanel>
        ))}
      </>
    </TabsContainer>
  )
}
export default CategoryTabs
