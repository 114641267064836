/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { GET_DASHBOARD } from 'gql/query'
import client from 'utils/createApolloHttpClient'

const INITIAL_DATA = { allCategories: [], allEvents: [], homePage: {} }

export type DashboardContextType = {
  allCategories: any
  allEvents: any
  homePage: any
}

export const DashboardContext =
  createContext<DashboardContextType>(INITIAL_DATA)

export const DashboardProvider = ({
  children,
  cmsApiKey
}: any): JSX.Element => {
  const { data = INITIAL_DATA, loading } = useQuery(GET_DASHBOARD, {
    client: client('https://graphql.datocms.com', cmsApiKey)
  })

  const { allCategories, allEvents, homePage } = data

  return (
    <DashboardContext.Provider value={{ allCategories, allEvents, homePage }}>
      {!loading && children}
    </DashboardContext.Provider>
  )
}
