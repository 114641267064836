/* eslint-disable no-console */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  ButtonProps,
  Chip,
  Divider,
  Grid,
  Typography,
  Snackbar,
  SnackbarProps
} from '@mui/material/'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ClearIcon from '@mui/icons-material/Clear'
import { Color, DialogWithoutAction } from 'components'
import { useMergeLink } from '@mergeapi/react-merge-link'
import useMerge from 'hooks/useMerge'
import { MergeContext } from 'context/mergeContext'
import { styled } from '@mui/material/styles'
import { CurrentAccount } from 'model/organization'

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  backgroundColor: '#050B0E',
  color: 'white',
  borderRadius: '8px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)'
}))

const StyledButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: 'IBM Plex Sans',
  lineHeight: '24px',
  color: '#FFFFFF',
  padding: '12px 16px',
  backgroundColor: '#000000',
  '&:hover': {
    backgroundColor: '#F2F3F3',
    color: '#050B0E'
  },
  '&:disabled': {
    backgroundColor: '#F2F3F3',
    color: '#a2a2a2'
  }
}))

interface IProps {
  currentAccount: CurrentAccount
}

const HRISIntegration = ({ currentAccount }: IProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const closeDialog = () => {
    setOpenConfirmDialog(false)
  }
  const hrisEnabled: boolean = currentAccount?.role === 'SUPER_MANAGER'
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const { getLinkToken, linkToken } = useMerge()
  const {
    linkedAccount,
    linkedAccountLoading,
    createLinkedAccount,
    deleteLinkedAccount
  } = useContext(MergeContext)

  const employerName = currentAccount?.employer.name

  useEffect(() => {
    if (!linkedAccount && !linkedAccountLoading && employerName) {
      getLinkToken({
        variables: {
          organizationName: employerName,
          category: 'hris'
        }
      })
    }
  }, [employerName, getLinkToken, linkedAccount, linkedAccountLoading])

  const onMergeSuccess = useCallback(
    (publicToken: String) => {
      createLinkedAccount({ variables: { publicToken } })
    },
    [createLinkedAccount]
  )

  const handleDeleteAccount = () => {
    deleteLinkedAccount()
    setOpenConfirmDialog(false)
  }

  const handleMergeError = () => {
    setSnackbarOpen(true)
    console.log(
      'HRIS integration was cancelled. There may have been an error or the user may have cancelled the operation.'
    )
  }

  const mergeConfig = {
    linkToken: linkToken?.linkToken,
    onSuccess: (public_token: String) => onMergeSuccess(public_token),
    onExit: () => handleMergeError()
  }

  const { open: openMerge } = useMergeLink(mergeConfig)

  const slackMessageInfo = {
    id: currentAccount?.id.split('/')[0],
    name: currentAccount?.user.name,
    employer: employerName
  }

  const handleContactMessage = async () => {
    const response = await fetch(`${process.env.REACT_APP_HRIS_ZAPIER_URL}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify({ slackMessageInfo })
    })
    const data = await response.json()
    if (data) {
      setMessageSent(true)
      setSnackbarOpen(true)
    }
  }

  return (
    <>
      <StyledSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ padding: '12px 17px', marginTop: '75px' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          {hrisEnabled
            ? 'HRIS integration was cancelled. Please try again.'
            : 'Your message was sent. We will be in touch shortly.'}
          <ClearIcon
            onClick={() => setSnackbarOpen(false)}
            sx={{
              marginLeft: '20px',
              fontSize: '16px',
              '&:hover': { cursor: 'pointer' }
            }}
          />
        </Typography>
      </StyledSnackbar>
      <DialogWithoutAction
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        title='Are you sure you want to disconnect your HRIS?'
        content='You will have to go through the set up process again if you decide to reconnect.'
        Action={
          <>
            <Button
              onClick={async () => {
                closeDialog()
              }}
              sx={{
                backgroundColor: '#F2F3F3',
                padding: '12px 16px',
                gap: 16,
                marginRight: '10px'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteAccount()
              }}
              variant='contained'
              sx={{ backgroundColor: '#373C3F' }}
            >
              Disconnect
            </Button>
          </>
        }
      />
      <Grid item xs={12}>
        <Divider flexItem sx={{ marginTop: '16px', marginBottom: '24px' }} />
      </Grid>
      <Grid item xs={12} container direction='row' alignItems='center'>
        <Divider flexItem sx={{ marginTop: '16px', marginBottom: '24px' }} />
        <Typography variant='h3' color={Color.black}>
          Human Resource Information System integration
        </Typography>
        <Chip
          label='Beta'
          size='small'
          style={{
            marginLeft: '8px',
            backgroundColor: '#FCF4CC',
            color: '#395260'
          }}
        />
      </Grid>
      <Grid>
        <Typography
          variant='body2'
          color={Color.black60}
          sx={{ width: '574px', marginTop: '26px' }}
        >
          View employee data from your HRIS and connect the dots between
          employee attrition, representation, and inclusion & belonging.
        </Typography>
        {linkedAccount ? (
          <Grid
            sx={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleIcon sx={{ color: 'green' }} />
              <Typography sx={{ marginLeft: '8px' }}>
                HRIS connected: <b>{linkedAccount.integrationName}</b>
              </Typography>
            </Box>
            <Button
              sx={{
                color: 'red',
                marginLeft: '13%',
                textDecoration: 'underline'
              }}
              onClick={() => {
                setOpenConfirmDialog(true)
              }}
            >
              Disconnect Integration
            </Button>
          </Grid>
        ) : (
          <Box sx={{ padding: '20px 0' }}>
            {hrisEnabled ? (
              <StyledButton onClick={() => openMerge(mergeConfig)}>
                Link your HRIS
                <ArrowForwardIcon sx={{ marginLeft: '5px' }} />
              </StyledButton>
            ) : (
              <StyledButton
                disabled={messageSent}
                onClick={() => handleContactMessage()}
              >
                Contact us to connect your HRIS
                <ArrowForwardIcon sx={{ marginLeft: '5px' }} />
              </StyledButton>
            )}
          </Box>
        )}
      </Grid>
    </>
  )
}

export default HRISIntegration
