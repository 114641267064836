/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useMutation as useApolloMutation, ApolloError } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { MutationHookOptions } from '@apollo/client/react/types/types'
import { OperationVariables } from '@apollo/client/core'
import { AlertContext } from 'context/AlertContext'
import { useContext } from 'react'

const useMutation: any = (
  document: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: MutationHookOptions<any, OperationVariables> | any
) => {
  const { displayAlertMessage } = useContext(AlertContext)
  return useApolloMutation(document, {
    ...options,
    onError: (error: ApolloError) => {
      if (!(options?.onErrorAction && options.onErrorAction(error))) {
        if (error.message) {
          displayAlertMessage(error.message)
          options?.setMarkDone && options.setMarkDone()
        }
      }
    },
    onCompleted: () => {
      if (options?.successNotification) {
        displayAlertMessage(options.successNotification)
      }
    }
  })
}

export default useMutation
