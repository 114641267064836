import React, { createContext, useEffect, useMemo, useState } from 'react'
import useMerge from 'hooks/useMerge'

export type MergeContextData = {
  linkedAccount: any
  linkedAccountLoading: boolean
  getLinkToken: (variables?: any) => any
  deleteLinkedAccount: () => any
  createLinkedAccount: (variables?: any) => any
}

const INITIAL_DATA: MergeContextData = {
  linkedAccount: undefined,
  linkedAccountLoading: true,
  getLinkToken: () => {},
  deleteLinkedAccount: () => {},
  createLinkedAccount: () => {}
}

export const MergeContext = createContext<MergeContextData>(INITIAL_DATA)

export const MergeProvider = ({ children, category }: any): JSX.Element => {
  const [linkedAccount, setLinkedAccount] = useState<any>(undefined)
  const {
    getLinkToken,
    getLinkedAccount,
    linkedAccountData,
    linkedAccountError,
    onDeleteLinkedAccount,
    linkedAccountLoading,
    createLinkedAccount
  } = useMerge()

  useEffect(() => {
    getLinkedAccount({ variables: { category } })
  }, [getLinkedAccount, category])

  useEffect(() => {
    if (linkedAccountData || linkedAccountError) {
      setLinkedAccount(linkedAccountData?.linkedAccount)
    }
  }, [linkedAccountData, linkedAccountError])

  const value = useMemo(() => {
    const deleteLinkedAccount = async () => {
      await onDeleteLinkedAccount({ variables: { category } })
      getLinkedAccount()
    }

    return {
      getLinkToken,
      linkedAccount,
      deleteLinkedAccount,
      linkedAccountLoading,
      createLinkedAccount
    }
  }, [
    category,
    getLinkToken,
    linkedAccount,
    onDeleteLinkedAccount,
    getLinkedAccount,
    linkedAccountLoading,
    createLinkedAccount
  ])
  return <MergeContext.Provider value={value}>{children}</MergeContext.Provider>
}
