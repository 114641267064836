/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react'
import { TakeActionContext } from 'context/TakeActionContext'
import {
  Divider,
  Grid,
  Typography,
  Button,
  Box,
  useMediaQuery
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Paper as MuiPaper, Color } from 'components'
import { styled, useTheme } from '@mui/material/styles'
import { ArrowForward } from '@mui/icons-material'
import LoadingCircular from 'components/LoadingCircular'
import {
  EIDashboardBlock,
  ScoreDeltaDashboard
} from 'components/SharedComponents'
import RecentGoals from './RecentGoals'

const Paper = styled(MuiPaper)({
  padding: '32px 24px',
  width: '100%'
})

const TakeAction = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1090))
  const isLargeScreen = useMediaQuery(theme.breakpoints.down(1675))
  const navigateToEi = () => {
    navigate('/equity-index')
  }
  const {
    totalScore,
    latestAssessmentQuarter,
    latestAssessmentYear,
    previousScore: previousAcquiredScore,
    previousQuarter,
    previousYear,
    goals,
    noAssessmentsCompleted,
    goalsAreLoading,
    assessmentDataIsLoading
  } = useContext(TakeActionContext)

  if (goalsAreLoading || assessmentDataIsLoading)
    return <LoadingCircular containerHeight={565} />

  // Show AP Widget if flag is turned on
  const showApWidget: boolean | undefined = false

  const goalGridWidth: number = showApWidget ? 4.95 : 8.45

  return (
    <>
      {!noAssessmentsCompleted ? (
        <>
          <Typography
            variant='h3'
            component='div'
            letterSpacing='0.1px'
            marginTop='48px'
            fontSize='28px'
            marginBottom='24px'
          >
            Plan & Take Action
          </Typography>
          <Paper>
            <Grid
              container
              spacing={2}
              direction={isSmallScreen ? 'column' : 'row'}
              justifyContent={
                isLargeScreen ? 'center !important' : 'flex-start'
              }
              sx={{
                '& > .MuiGrid-item': {
                  paddingLeft: isLargeScreen ? '10px !important' : undefined,
                  paddingRight: isLargeScreen ? '0 !important' : undefined
                }
              }}
            >
              <Grid item xs={12} md={3.5}>
                <Typography
                  variant='h7'
                  component='div'
                  sx={{
                    textAlign: 'center',
                    marginTop: '26px',
                    [theme.breakpoints.down(1509)]: {
                      marginBottom: '35px'
                    }
                  }}
                  onClick={() => navigate('/equity-index')}
                >
                  EQUITY INDEX SCORE
                </Typography>
                <EIDashboardBlock
                  totalScore={totalScore || 0}
                  navigateToEi={navigateToEi}
                />
                {previousAcquiredScore ? (
                  <ScoreDeltaDashboard
                    totalScore={totalScore || 0}
                    previousAcquiredScore={previousAcquiredScore || 0}
                    previousQuarter={previousQuarter || 0}
                    previousYear={previousYear || 0}
                  />
                ) : (
                  <Box sx={{ paddingTop: '25px' }}>
                    {latestAssessmentQuarter && latestAssessmentYear && (
                      <Typography
                        variant='subtitle1'
                        sx={{
                          textAlign: 'center',
                          fontWeight: 700,
                          letterSpacing: '0.17em',
                          color: Color.black
                        }}
                      >
                        {`Q${latestAssessmentQuarter} — ${latestAssessmentYear}`}
                      </Typography>
                    )}
                  </Box>
                )}
              </Grid>
              <Divider orientation='vertical' flexItem />
              {showApWidget && (
                <>
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={12} md={3.5}>
                    <Typography
                      variant='h7'
                      component='div'
                      sx={{ textAlign: 'center', marginTop: '26px' }}
                    >
                      GOAL STATUS
                    </Typography>
                  </Grid>
                  <Divider orientation='vertical' flexItem />
                </>
              )}
              {isSmallScreen && (
                <Divider
                  orientation='horizontal'
                  flexItem
                  sx={{ marginTop: '15px' }}
                />
              )}

              <Grid
                item
                xs={12}
                md={goalGridWidth}
                sx={{ paddingLeft: { md: '48px' } }}
              >
                <RecentGoals goals={goals} />
              </Grid>
            </Grid>
            <Divider flexItem sx={{ marginTop: '32px' }} />
            <Button
              variant='contained'
              sx={{
                marginLeft: 'auto',
                marginRight: 0,
                marginTop: '40px',
                display: 'flex'
              }}
              onClick={() => {
                navigate('/action-plan')
              }}
              endIcon={<ArrowForward />}
            >
              See Action Plan
            </Button>
          </Paper>
        </>
      ) : null}
    </>
  )
}
export default TakeAction
