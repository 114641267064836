import React, { useState, useContext } from 'react'
import { Typography, Tabs, Tab, Grid } from '@mui/material'
import { Color, TabPanel } from 'components'
import { MicroServiceContext } from 'context/MicroService'
import { DepartmentsProvider } from 'context/DepartmentsContext'
import { EmployerContext } from 'context/EmployerProvider'
import { StaffAccountProvider } from 'context/StaffAccountProvider'
import useStyles from './style'
import Overview from './Overview'
import StaffMember from './Staff'
import { MyTabs } from './constants'
import Departments from './Departments'
import UnderMaintenance from './UnderMaintenance'

const Organization = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const { isFlagEnabled } = useContext(MicroServiceContext)
  const { myEmployer } = useContext(EmployerContext)

  const showDepartments: boolean = isFlagEnabled('enable-departments')
  const staffManagementDisabled: boolean = isFlagEnabled('disable-staff-admin')

  return (
    <Grid className={classes.container}>
      <Typography variant='h1' color={Color.black}>
        Organization settings{` `}
        <Typography component='span' variant='h1' sx={{ fontWeight: 400 }}>
          ({myEmployer.name})
        </Typography>
      </Typography>
      <Tabs
        value={selectedTab}
        className={classes.tabs}
        onChange={(_e, value) => {
          setSelectedTab(value)
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: Color.main,
            height: 2,
            marginLeft:
              selectedTab === MyTabs.StaffMembers ||
              selectedTab === MyTabs.Departments
                ? '18px'
                : 0
          }
        }}
      >
        <Tab
          label={
            <Typography variant='body2Bold' fontWeight='500'>
              Overview
            </Typography>
          }
        />
        <Tab
          label={
            <Typography
              variant='body2Bold'
              fontWeight='500'
              sx={{ marginLeft: '32px' }}
            >
              Staff members
            </Typography>
          }
        />
        {showDepartments && (
          <Tab
            label={
              <Typography
                variant='body2Bold'
                fontWeight='500'
                sx={{ marginLeft: '32px' }}
              >
                Departments
              </Typography>
            }
          />
        )}
      </Tabs>
      <TabPanel value={selectedTab} index={MyTabs.Overview}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Overview myEmployer={myEmployer} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTab} index={MyTabs.StaffMembers}>
        <StaffAccountProvider>
          {staffManagementDisabled ? <UnderMaintenance /> : <StaffMember />}
        </StaffAccountProvider>
      </TabPanel>
      {showDepartments && (
        <TabPanel value={selectedTab} index={MyTabs.Departments}>
          <DepartmentsProvider>
            <Departments />
          </DepartmentsProvider>
        </TabPanel>
      )}
    </Grid>
  )
}
export default Organization
