import React, { useState } from 'react'
import { Typography, Grid, Button, ButtonProps } from '@mui/material'
import { Color } from 'components'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { styled } from '@mui/material/styles'
import { FilterIndex } from '../constants'
import useStyles from './style'
import {
  ROLE_OPTIONS,
  EHI_ACCESS_OPTIONS,
  STATUS_OPTIONS
} from '../TableRowBuilder/constants'
import Dialogs from './dialogs'

interface Props {
  filterState: any
  dispatch: (data: any) => void
}

interface FilterButtonProps extends ButtonProps {
  defaultState?: boolean
}

const FilterButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'defaultState'
})<FilterButtonProps>(({ defaultState }) => ({
  height: 32,
  paddingLeft: 2,
  marginRight: 8,
  paddingRight: 6,
  borderRadius: 20,
  borderColor: Color.main,
  border: defaultState ? `1px solid ${Color.black}` : '',
  color: defaultState ? Color.black : ''
}))
const SecondaryFilters = (props: Props) => {
  const { filterState, dispatch } = props

  const [roleAnchorEl, setRoleAnchorEl] = React.useState<
    undefined | HTMLElement
  >()
  const [editingType, setEditingType] = useState('')

  const [items, setItems] = useState<any[]>([])
  const classes = useStyles()

  return (
    <>
      <Grid
        item
        sx={{
          justifyContent: 'flex-end',
          display: 'inline-flex',
          marginLeft: 'auto'
        }}
      >
        <Grid item className={classes.noPaddingTop}>
          <FilterButton
            variant='outlined'
            defaultState={filterState.statuses.length === 0}
            onClick={(e: any) => {
              setRoleAnchorEl(e.currentTarget)
              setEditingType(FilterIndex.statuses)
              setItems(STATUS_OPTIONS)
            }}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowDropDownIcon className={classes.defaultStateIcon} />}
          >
            <Typography
              className={classes.filterLabel}
              color={Color.black80}
              variant='body2'
            >
              Status
            </Typography>
            <Typography
              className={classes.filterValue}
              color={Color.black80}
              variant='body2'
            >
              {filterState.statuses.length > 0 && (
                <span className={classes.roundDot}>
                  {filterState.statuses.length}
                </span>
              )}
            </Typography>
          </FilterButton>
        </Grid>
        <Grid item className={classes.noPaddingTop}>
          <FilterButton
            variant='outlined'
            defaultState={filterState.roles.length === 0}
            onClick={(e: any) => {
              setRoleAnchorEl(e.currentTarget)
              setEditingType(FilterIndex.roles)
              setItems([...ROLE_OPTIONS, ...EHI_ACCESS_OPTIONS])
            }}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowDropDownIcon className={classes.defaultStateIcon} />}
          >
            <Typography
              className={classes.filterLabel}
              color={Color.black80}
              variant='body2'
            >
              Role
            </Typography>
            <Typography
              className={classes.filterValue}
              color={Color.black80}
              variant='body2'
            >
              {filterState.roles.length > 0 && (
                <span className={classes.roundDot}>
                  {filterState.roles.length}
                </span>
              )}
            </Typography>
          </FilterButton>
        </Grid>
      </Grid>
      <Dialogs
        filterState={filterState}
        dispatch={dispatch}
        items={items}
        editingType={editingType}
        setRoleAnchorEl={setRoleAnchorEl}
        roleAnchorEl={roleAnchorEl}
      />
    </>
  )
}

export default SecondaryFilters
