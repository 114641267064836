import React from 'react'
import Stack from '@mui/material/Stack'
import { ContentTypeChip } from './ContentTypeChip'
import DateTimeChip from '../DateTimeChip/DateTimeChip'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import ReadViewTimeChip from '../ReadTimeViewChip/ReadTimeViewChip'

interface CardEyebrowProps {
  label: string
  backgroundColor: string
  onDemand?: boolean | null | undefined
  readTime?: number | undefined
  dateTime?: string | undefined
  bookmark?: () => void
  cardGroup?: 'two' | 'three' | undefined
}

const ChipWrapper = styled(Box)<BoxProps>(() => ({
  marginTop: '3.5px'
}))

export const CardEyebrow = ({
  label,
  backgroundColor,
  onDemand,
  readTime,
  dateTime,
  cardGroup
}: CardEyebrowProps) => {
  return (
    <Stack direction='row'>
      <ChipWrapper>
        <ContentTypeChip
          cardGroup={cardGroup}
          label={label}
          backgroundColor={backgroundColor}
        />
        {dateTime ? (
          <DateTimeChip
            cardGroup={cardGroup}
            date={dateTime}
            onDemand={onDemand}
          />
        ) : (
          <>
            {readTime ? (
              <ReadViewTimeChip time={readTime} onPage={false} />
            ) : null}
          </>
        )}
      </ChipWrapper>
    </Stack>
  )
}

export default CardEyebrow
