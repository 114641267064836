import React, { useMemo, useCallback } from 'react'
import CustomSnackbar from 'components/CustomSnackbar'

interface State {
  type: string
  open: boolean
  message: string
}

const initialState: State = {
  type: 'close',
  open: false,
  message: ''
}

type Action =
  | { type: 'close' }
  | {
      type: 'open'
      payload: { message: string }
    }

export type ContextData = {
  state: State
  dispatch: React.Dispatch<Action>
  displayAlertMessage: (message: string) => void
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'close':
      return { ...state, open: false }

    case 'open':
      return { ...state, open: true, message: action.payload.message }

    default:
      return { ...initialState }
  }
}

export const AlertContext = React.createContext<ContextData>({
  state: { ...initialState },
  dispatch: () => {},
  displayAlertMessage: () => {}
})

export const AlertProvider = ({ children }: any): JSX.Element => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const handleOpen = (): void => {
    dispatch({ type: 'close' })
  }

  const displayAlertMessage = useCallback(
    (message: string) => {
      dispatch({ type: 'open', payload: { message } })
    },
    [dispatch]
  )

  const value = useMemo(
    () => ({ state, dispatch, displayAlertMessage }),
    [state, dispatch, displayAlertMessage]
  )

  return (
    <AlertContext.Provider value={value}>
      <CustomSnackbar
        open={state.open}
        content={state.message}
        onClose={handleOpen}
      />
      {children}
    </AlertContext.Provider>
  )
}
