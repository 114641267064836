/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react'
import { Typography, Button, Box, Stack } from '@mui/material'
import { Color } from 'components'
import { BuildCircle as BuildIcon } from '@mui/icons-material'

interface UnderMaintenanceProps {
  hoursToFinish?: number | undefined | null
}

const UnderMaintenance = ({ hoursToFinish }: UnderMaintenanceProps) => {
  return (
    <Box>
      <Box>
        <Stack direction='row' alignItems='center'>
          <BuildIcon
            sx={{ marginRight: '5px', paddingTop: '5px', fontSize: '45px' }}
          />
          <Typography variant='largeTitle' color={Color.black}>
            Under Maintenance
          </Typography>
        </Stack>
        <Box>
          <Box sx={{ margin: '20px 0' }}>
            <Typography
              variant='body1High'
              color='#64737D'
              className='introduction'
            >
              This page is currently undergoing maintenance. Please try again
              shortly.{' '}
              {hoursToFinish && hoursToFinish > 0
                ? hoursToFinish === 1
                  ? `We expect to be done within 1 hour.`
                  : `We expect to be done within ${hoursToFinish} hours.`
                : ''}
            </Typography>
          </Box>

          <Button
            variant='contained'
            href='/'
            sx={{
              backgroundColor: Color.black,
              color: '#FFFFFF !important',
              '&:hover': {
                backgroundColor: Color.grey200,
                color: '#000000 !important'
              }
            }}
          >
            Back to Home Page
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
export default UnderMaintenance
