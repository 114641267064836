import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
    paddingTop: '28vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '& .container': {
      display: 'flex',
      width: 600,
      marginLeft: 136,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '& .introduction': {
        marginTop: 40
      },
      '& .backBtn': {
        padding: '12px 24px',
        marginTop: 56
      }
    }
  }
}))

export default useStyles
