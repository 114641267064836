import moment from 'moment'
import {
  EmployerAssessmentHistory,
  CurrentEpAssessments,
  CurrentEpAssessment,
  Quarter
} from 'model/takeAction'

export const getLastFourQuarters = (
  previousEmployerAssessmentHistory?: EmployerAssessmentHistory,
  currentEmployerAssessmentHistory?: EmployerAssessmentHistory
): CurrentEpAssessments => {
  const recentFourQuarterAssessment: CurrentEpAssessments = {
    1: null,
    2: null,
    3: null,
    4: null
  }
  if (!previousEmployerAssessmentHistory || !currentEmployerAssessmentHistory)
    return recentFourQuarterAssessment
  // set constant values needed for constructing the CurrentEpAssessments
  const currentQuarter: number = Math.ceil((moment().utc().month() + 1) / 3)
  const currentYear: number = moment().utc().year()
  const previousYear: number = currentYear - 1
  let quarterCounter: number = 4 // total needed to construct object

  // start with this year's assessment and work backwards from the current quarter
  for (let i = 0; i < currentQuarter; i += 1) {
    const assessment: CurrentEpAssessment = {
      quarter: currentQuarter - i,
      year: currentYear,
      quarterAssessment:
        currentEmployerAssessmentHistory[
          Quarter[
            currentQuarter - i - 1
          ] as keyof typeof currentEmployerAssessmentHistory
        ]
    }
    recentFourQuarterAssessment[
      quarterCounter as keyof typeof recentFourQuarterAssessment
    ] = assessment
    quarterCounter -= 1
  }

  // finish with last year's assessment and how many quarters are left - starting from the 4th quarter and working backwards
  for (let i = 3; quarterCounter > 0; i -= 1) {
    const assessment: CurrentEpAssessment = {
      quarter: i + 1,
      year: previousYear,
      quarterAssessment:
        previousEmployerAssessmentHistory[
          Quarter[i] as keyof typeof previousEmployerAssessmentHistory
        ]
    }
    recentFourQuarterAssessment[
      quarterCounter as keyof typeof recentFourQuarterAssessment
    ] = assessment
    quarterCounter -= 1
  }

  return recentFourQuarterAssessment
}

export const getPreviousAndCurrentAssessments = (
  currentEpAsessments: CurrentEpAssessments
) => {
  if (!currentEpAsessments) return []
  const previousAndCurrentScores = []

  // Update to compare the last four quarters
  if (currentEpAsessments[1]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[1]?.quarter,
      ...currentEpAsessments[1]?.quarterAssessment
    })
  if (currentEpAsessments[2]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[2]?.quarter,
      ...currentEpAsessments[2]?.quarterAssessment
    })
  if (currentEpAsessments[3]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[3]?.quarter,
      ...currentEpAsessments[3]?.quarterAssessment
    })
  if (currentEpAsessments[4]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[4]?.quarter,
      ...currentEpAsessments[4]?.quarterAssessment
    })

  return previousAndCurrentScores.length > 1
    ? previousAndCurrentScores.slice(-2)
    : [undefined, ...previousAndCurrentScores]
}

export const getLatestAssessmentQuarter = (
  month: number | undefined
): number => {
  if (!month) return 0
  return Math.ceil(month / 3)
}
