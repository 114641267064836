import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_LATEST_ASSESSMENT, EMPLOYER_GOALS } from 'gql/takeAction'
import moment from 'moment'
import { MicroServiceContext } from 'context/MicroService'
import { GoalNode } from 'model/takeAction'

const useTakeAction = () => {
  const { maestroClient, currentAccount, isFlagEnabled } =
    useContext(MicroServiceContext)
  const [epGoals, setEpGoals] = useState<any>()
  const [currentYearAssessments, setCurrentYearAssessments] = useState<any>()
  const [previousYearAssessments, setPreviousYearAssessments] = useState<any>()
  const [latestAssessment, setLatestAssessment] = useState<any>()
  const currentYear: number = moment().utc().year()

  // GET previous year's assessments
  const { data: rawPreviousYearAssessments, loading: previousYearIsLoading } =
    useQuery(GET_LATEST_ASSESSMENT, {
      client: maestroClient,
      variables: { year: (currentYear - 1).toString() }
    })

  // GET current year's assessments
  const { data: rawCurrentYearAssessments, loading: currentYearIsLoading } =
    useQuery(GET_LATEST_ASSESSMENT, {
      client: maestroClient,
      variables: { year: currentYear.toString() }
    })

  // GET employer goals
  const { data: rawGoals, loading: goalsAreLoading } = useQuery(
    EMPLOYER_GOALS,
    {
      client: maestroClient,
      variables: { employerId: currentAccount.employer.id }
    }
  )

  // PUBLIC METHODS
  const getRecentGoals = (): GoalNode[] => {
    if (epGoals) {
      const items = epGoals.edges

      return items
        .filter((item: GoalNode) => item.node)
        .sort(
          (a: GoalNode, b: GoalNode) =>
            new Date(b.node.updatedAt).getTime() -
            new Date(a.node.updatedAt).getTime()
        )
        .slice(0, 4)
    }
    return []
  }

  // state management
  useEffect(() => {
    if (rawGoals) {
      const { employerGoals } = rawGoals
      setEpGoals(employerGoals)
    }
  }, [rawGoals])

  useEffect(() => {
    if (rawCurrentYearAssessments) {
      setCurrentYearAssessments(rawCurrentYearAssessments)
      setLatestAssessment(
        rawCurrentYearAssessments.employerAssessmentHistory.latestAssessment
      )
    }
  }, [rawCurrentYearAssessments])

  useEffect(() => {
    if (rawPreviousYearAssessments) {
      setPreviousYearAssessments(rawPreviousYearAssessments)
    }
  }, [rawPreviousYearAssessments])

  const assessmentDataIsLoading: boolean =
    currentYearIsLoading || previousYearIsLoading

  return {
    assessmentDataIsLoading,
    currentYearAssessments,
    previousYearAssessments,
    latestAssessment,
    goalsAreLoading,
    getRecentGoals,
    isFlagEnabled
  }
}

export default useTakeAction
