import { gql, useMutation, useQuery } from '@apollo/client'

const EMPLOYER_AUTHENTICATION_SETTINGS = gql`
  query {
    employerAuthenticationSettings {
      domain
      employerId
      idpType
      strategy
    }
  }
`
const UPDATE_EMPLOYER_AUTHENTICATION_SETTINGS = gql`
  mutation UpdateEmployerAuthenticationSetting(
    $input: UpdateEmployerAuthenticationSettingInput!
  ) {
    updateEmployerAuthenticationSetting(input: $input) {
      domain
      employerId
      idpType
      strategy
    }
  }
`
const useAuthenticationSettings = () => {
  const {
    data: { employerAuthenticationSettings } = {
      strategy: 'DOMAIN_LOCK',
      __typename: 'EmployerAuthenticationSetting'
    }
  } = useQuery(EMPLOYER_AUTHENTICATION_SETTINGS)
  const [updateEmployerAuthenticationSettings] = useMutation(
    UPDATE_EMPLOYER_AUTHENTICATION_SETTINGS
  )
  return {
    employerAuthenticationSettings,
    updateEmployerAuthenticationSettings
  }
}
export default useAuthenticationSettings
