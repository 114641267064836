import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import CardCarousel from 'components/CMS/CardCarousel'
import { DashboardContext } from 'context/DashboardContext'
import VerticalCardList from 'components/CMS/VerticalCardList'

const Events = () => {
  const { allEvents } = useContext(DashboardContext)
  return (
    <Grid container>
      <Typography
        variant='h3'
        component='div'
        letterSpacing='0.1px'
        marginTop='72px'
        fontSize='28px'
      >
        Trending Resources
      </Typography>

      {window.innerWidth > 1200 ? (
        <CardCarousel contentArray={allEvents} />
      ) : (
        <VerticalCardList contentCards={allEvents} />
      )}
    </Grid>
  )
}
export default Events
