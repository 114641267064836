import * as React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

interface TagChipProps {
  tag: string
}

const StyledChip = styled(Chip)<ChipProps>(() => ({
  fontFamily: '"IBM Plex Sans", sans-serif',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: '1px',
  color: '#000000',
  backgroundColor: '#CCCCCC',
  padding: '0px 3px 4px',
  height: '36px',
  borderRadius: '25px'
}))

const TagChip = (props: TagChipProps) => {
  return <StyledChip label={props.tag} />
}

export default TagChip
