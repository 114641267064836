import useStaffAccount from 'hooks/useStaffAccount'
import { EmployerStaffAccountEdge } from 'model'
import React, { createContext, useMemo } from 'react'

export type StaffAccountContextData = {
  inviteStaff: (variables: any) => any
  resendInvite: (variables: any) => void
  employerStaffAccounts: EmployerStaffAccountEdge[]
  totalCount: number
  loading: boolean
  paginationState: any
  filterState: any
  dispatchFilter: any
  dispatchPaginationState: any
  fetchMore: any
  changePermission: (variables: any) => any
  deactiveStaff: (variables: any) => any
  activateStaff: (variables: any) => any
}

const INITIAL_DATA: StaffAccountContextData = {
  inviteStaff: () => {},
  resendInvite: () => {},
  employerStaffAccounts: [],
  totalCount: 0,
  loading: true,
  paginationState: {},
  filterState: {},
  dispatchFilter: () => {},
  dispatchPaginationState: () => {},
  fetchMore: () => {},
  changePermission: () => {},
  deactiveStaff: () => {},
  activateStaff: () => {}
}

export const StaffAccountContext =
  createContext<StaffAccountContextData>(INITIAL_DATA)

export const StaffAccountProvider = ({ children }: any): JSX.Element => {
  const {
    inviteStaff,
    resendInvite,
    employerStaffAccounts,
    loading,
    paginationState,
    filterState,
    dispatchFilter,
    dispatchPaginationState,
    fetchMore,
    changePermission,
    deactiveStaff,
    activateStaff
  } = useStaffAccount()

  const value = useMemo(
    () => ({
      inviteStaff,
      resendInvite,
      employerStaffAccounts: employerStaffAccounts.edges,
      totalCount: employerStaffAccounts.totalCount,
      loading,
      paginationState,
      filterState,
      dispatchFilter,
      dispatchPaginationState,
      fetchMore,
      changePermission,
      deactiveStaff,
      activateStaff
    }),
    [
      employerStaffAccounts.edges,
      employerStaffAccounts.totalCount,
      inviteStaff,
      loading,
      resendInvite,
      paginationState,
      filterState,
      dispatchFilter,
      dispatchPaginationState,
      fetchMore,
      changePermission,
      deactiveStaff,
      activateStaff
    ]
  )
  return (
    <StaffAccountContext.Provider value={value}>
      {children}
    </StaffAccountContext.Provider>
  )
}
