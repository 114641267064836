import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils'

export enum ContentBucketType {
  'book' = 'BOOK',
  'case-study' = 'CASE_STUDY',
  'event' = 'EVENT',
  'video' = 'VIDEO',
  'toolkit' = 'TOOLKIT',
  'interview' = 'INTERVIEW',
  'how-to' = 'HOW_TO'
}

export enum ContentLabel {
  'event' = 'EVENT',
  'book' = 'BOOK',
  'case-study' = 'CASE STUDY',
  'video' = 'VIDEO',
  'toolkit' = 'TOOLKIT',
  'interview' = 'INTERVIEW',
  'how-to' = 'HOW TO'
}

export enum Colors {
  'EVENT' = '#AACDF4',
  'BOOK' = 'rgba(128, 11, 138, 0.5)',
  'CASE_STUDY' = '#D6DF6B',
  'INTERVIEW' = 'rgba(255, 213, 0, 0.9)',
  'VIDEO' = 'rgba(78, 41, 226, 0.6)',
  'TOOLKIT' = 'rgba(21, 161, 145, 0.5)',
  'HOW_TO' = 'rgba(244, 58, 47, 0.5)',
  'DEFAULT' = '#CCCCCC'
}

export interface QueryLimit {
  limit: number
}

export type ContentBucket = {
  id?: string | undefined
  slug: string
}

export type Category = {
  slug: string
}

export interface CategoryData extends Category {
  __typename: string
  id: string
  name: string
  slug: string
  blurb: string
  featuredArticles: CmsArticle[]
}

export type CmsImage = {
  responsiveImage: any
  factoidImage?: boolean | undefined
}

export type CmsPdf = {
  url: string
  alt?: string | undefined
}

export type BaseArticle = {
  title: string
  blurb: string
  contentBucket: ContentBucket
  category: Category
  startDate?: string | undefined
  onDemand?: boolean | null | undefined
  image?: CmsImage | undefined
  minutes?: number | undefined
  tags?: string[] | undefined
}

export interface CmsArticle extends BaseArticle {
  slug: string
  pdf?: CmsPdf | undefined
  externalUrl?: string | undefined
  __typename?: string | undefined
}

export interface Chapter extends BaseArticle {
  slug: string
  chapterNumber: string
}

export type CategoryBuckets = {
  allEvents: CmsArticle[]
  allHowTos: CmsArticle[]
  allInterviews: CmsArticle[]
  allToolkits: CmsArticle[]
  allVideos: CmsArticle[]
}

export type TabContent = {
  blurb: string
  name: string
  slug: string
  featuredArticles: CmsArticle[]
}

export type CmsListItem = {
  listItemContent: StructuredTextType
}

export type TranscriptType = {
  minuteMark: number
  secondsMark: number
  copy: StructuredTextType
}

export type ToolkitSlide = {
  id: string
  slug: string
  title: string
  eyebrow: string
  minutes: number
  pdf: CmsPdf
  slideNumber: number
  content: StructuredTextType
}

export interface Toolkit extends CmsArticle {
  slides: ToolkitSlide[]
}
