import { PaginationState } from 'model'

export const INITIAL_STAFF_MEMBER_FILTER = {
  roles: [],
  name: '',
  statuses: ['ACTIVE', 'PENDING']
}

export const INITIA_PAGINATION_STATE: PaginationState = {
  currentPage: 0,
  rowsPerPage: 10,
  cursor: undefined
}
