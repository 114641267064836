import React, { useContext } from 'react'

import { Typography, TextField, Select, MenuItem } from '@mui/material'
import { AuthenticationSettings } from 'model/organization'
import { MicroServiceContext } from 'context/MicroService'
import { ServiceProvider, DomainState } from './constants'
import useStyles from './style'

interface Props {
  dispatch: (options: any) => void
  authenticationSettings: AuthenticationSettings
  domainState: DomainState
}
const DomainLogIn = (props: Props) => {
  const { authenticationSettings, domainState, dispatch } = props
  const classes = useStyles()
  const { currentAccount } = useContext(MicroServiceContext)

  return (
    <>
      <Typography
        variant='formLabel'
        marginTop='24px'
        className={classes.inputBox}
      >
        Company domain*
      </Typography>
      <TextField
        variant='outlined'
        fullWidth
        placeholder={
          authenticationSettings.domain ||
          currentAccount.user.primaryEmail.split('@')[1]
        }
        value={domainState.companyDomain}
        InputProps={{
          startAdornment: '@'
        }}
        disabled
      />
      <Typography
        variant='formLabel'
        marginTop='24px'
        className={classes.inputBox}
      >
        Service provider*
      </Typography>
      <Select
        value={domainState.serviceProvider}
        onChange={e => {
          dispatch({
            index: 'serviceProvider',
            value: e.target.value
          })
        }}
        className={classes.selectBox}
        fullWidth
        MenuProps={{ MenuListProps: { className: classes.menuList } }}
        SelectDisplayProps={{
          className: classes.selectDisplay
        }}
      >
        {Object.keys(ServiceProvider).map((provider: string) => {
          return (
            <MenuItem key={provider} value={(ServiceProvider as any)[provider]}>
              {provider}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}
export default DomainLogIn
