import { Components, Icons } from '../importHandler'

export const {
  InfoIcon,
  EditIcon,
  EmployerPartnersIcon,
  LeadsIcon,
  SettingsIcon,
  TalentServicesIcon,
  ErrorIcon,
  AddIcon
} = Icons

export const {
  theme,
  Color,
  Paper,
  UserAvatar,
  Dialog,
  DialogWithoutAction,
  Table,
  TableContext,
  useDebounceInput,
  TableContextProvider,
  NotificationContext,
  NotificationProvider,
  InfoBanner,
  TabPanel,
  GoalTag,
  EIDashboardBlock,
  ScoreDeltaDashboard
} = Components
