import { gql } from '@apollo/client'

export const GET_LATEST_ASSESSMENT = gql`
  query ($year: String) {
    employerAssessmentHistory(year: $year) {
      latestAssessment {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
      firstQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
      secondQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
      thirdQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
      fourthQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
    }
  }
`

const GoalInput = gql`
  fragment goalInput on EmployerGoal {
    customTitle
    activeStatus
    startDate
    updatedAt
    endDate
    owner {
      id
      name
      primaryEmail
    }
    members {
      id
      name
      primaryEmail
    }
    note
    isCompleted
    completedAt
  }
`
export const EMPLOYER_GOALS = gql`
  query EmployerGoal($employerId: String!) {
    employerGoals(employerId: $employerId, first: 50) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          employerId
          activeStatus
          category
          onTrack
          phase
          description
          assessmentVersion
          assessmentQuestion
          kcUrls
          goalId
          scoreDelta
          ...goalInput
        }
      }
    }
  }
  ${GoalInput}
`
