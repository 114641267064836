import React from 'react'
import { PopupSelectList } from 'components'

interface Props {
  filterState: any
  dispatch: (data: any) => void
  items: string[]
  editingType: string
  setRoleAnchorEl: (el: any) => void

  roleAnchorEl: any
}

const Dialogs = (props: Props) => {
  const {
    filterState,
    dispatch,
    items,
    editingType,
    setRoleAnchorEl,
    roleAnchorEl
  } = props

  const updateSelectedItems = {
    add: (content: any) => {
      const selectedItems = [...filterState[editingType]]
      selectedItems.push(content)
      dispatch({ index: editingType, value: selectedItems })
    },
    remove: (content: any) => {
      const selectedItems = filterState[editingType].filter(
        (item: any) => item !== content
      )
      dispatch({ index: editingType, value: selectedItems })
    },

    clear: () => {
      dispatch({ index: editingType, value: [] })
    }
  }

  return (
    <PopupSelectList
      anchorEl={roleAnchorEl}
      setAnchorEl={setRoleAnchorEl}
      items={items}
      updateSelectedItems={updateSelectedItems}
      selectedItems={filterState[editingType]}
    />
  )
}

export default Dialogs
